import { useQuery, UseQueryResult, UseQueryOptions, QueryKey } from '@tanstack/react-query';
import { PolicyList } from '../../../../models/PolicySummary';
import PolicyService from '../../../../services/policySummary/PolicyService';

export const usePolicyData = (
  id: string,
  option?: UseQueryOptions<PolicyList, unknown, PolicyList, QueryKey>
): UseQueryResult<PolicyList, any> => {
  const policyList = useQuery<PolicyList>(
    ['customer-policies', id],
    () => PolicyService.list(id),
    option
  );

  return policyList;
};
