import { Row, Column, FormDropdownSingleSelect, Input } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';
import { noop } from 'lodash';
import { useFormikContext } from 'formik';
import { useLogFormDropdowns } from './useLogFormDropdown';
import * as fieldNames from './fieldNames';
import { useFormatting } from '../../common/hooks/useFormatting';
import LocalizedFormDatePickerInput from '../../common/localizedFormDatePickerInput/LocalizedFormDatePickerInput';

const LogFormControlActivity = () => {
  const { formatMessage, locale } = useIntl();
  const logformdropdowns = useLogFormDropdowns({
    refetchOnWindowFocus: false,
  });

  const { formatCurrency } = useFormatting();

  const CanAccessStages = logformdropdowns?.data?.CanAccessStages;

  const { getFieldProps } = useFormikContext();

  return (
    <React.Fragment>
      <Row>
        <Column>
          <FormDropdownSingleSelect
            name={fieldNames.STAGE}
            data-testid="logformControlStage-stage"
            label={formatMessage({ id: 'logform.saleStage' })}
            options={
              logformdropdowns?.data?.SaleStages.map((data) => {
                return { value: data.Key, label: data.Value };
              }) ?? []
            }
            disabled={!CanAccessStages}
            placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
          ></FormDropdownSingleSelect>
        </Column>
        <Column>
          <Input
            name={fieldNames.PREMIUM}
            data-testid="logformControlStage-premium"
            label={formatMessage({ id: 'logform.premium' })}
            value={formatCurrency(Number(getFieldProps(fieldNames.PREMIUM).value), 0, locale)}
            onChange={noop}
            disabled={true}
          />
        </Column>
        <Column>
          <LocalizedFormDatePickerInput
            name={fieldNames.CLOSEDATE}
            data-testid="logformControlStage-closedate"
            label={formatMessage({ id: 'logform.closeDate' })}
            disabled={!CanAccessStages}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

export default LogFormControlActivity;
