import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { LogFormServices } from '../../../services/logForm/LogFormService';
import { LogFormActivityExtData } from '../../../models/LogFormData';

export const useLogFormActivityExt = (actid: string): UseQueryResult<LogFormActivityExtData> => {
  const activityext = useQuery<LogFormActivityExtData>(['customer-activity-ext', actid], () =>
    LogFormServices.getext(actid)
  );
  return activityext;
};
