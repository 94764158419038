import { useMutation } from '@tanstack/react-query';
import {
  ToDoListCompleteStatusCriteria,
  ToDoListCompleteStatusResult,
} from '../../../../models/ToDo';
import TodoListService from '../../../../services/todoList/TodoListService';

export const useToDoListChangeStatus = (
  onSuccess: (
    data: ToDoListCompleteStatusResult,
    variables: ToDoListCompleteStatusCriteria,
    context: unknown
  ) => void | undefined
) => {
  const suspenseMutation = useMutation(
    async (params: ToDoListCompleteStatusCriteria) =>
      await TodoListService.updateSuspenseStatus({ Status: params.Status, SuspId: params.SuspId }),
    {
      onSuccess,
    }
  );

  return suspenseMutation;
};
