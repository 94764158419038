import {
  Button,
  ButtonGroup,
  ContentHeader,
  DropdownButtonProvider,
  DropdownLinkButton,
  DropdownList,
  DropdownListLinkItem,
  Link,
  Modal,
  Spacer,
  Typography,
} from 'alloy-foundation';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
// import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { BusinessProcessTemplateRestModel } from '../../../models/smartflow/BusinessProcessTemplateRestModel';
import { StringColumn } from '../../common/custom-datatable-columns/StringColumn';
import { SwitchDataColumn } from '../../common/custom-datatable-columns/SwitchColumn';
import { useVisible } from '../../common/hooks/useVisible';
import LocalizedDataTable from '../../common/localizedDataTable/LocalizedDataTable';
import DefaultPageLayout from '../../common/page-layout/DefaultPageLayout';
import { DataTableColumn, HighlightFilterValue } from '../../data-table/DataTableColumn';
import { useDataTable, useDataTableRows } from '../../data-table/useDataTable';
import {
  useQuickNav,
  useUpdateQuickNavTemplateStatus,
  updateQuickNavTemplatesCache,
} from './useQuickNav';
// import stylesFn from './styles';
import { withLayoutShell } from '../../common/hoc/withLayoutShell';

const QuickNavConfigurationMain = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const queryClient = useQueryClient();
  const quickNavQuery = useQuickNav();
  const { visible, SetVisible } = useVisible();
  const [activeRow, setActiveRow] = useState<BusinessProcessTemplateRestModel>(null);
  const updateMutation = useUpdateQuickNavTemplateStatus({
    onSuccess: (data, variable, context) => {
      variable.template.IsEnabled = !variable.template.IsEnabled;
      variable.template.PublishedVariationsCount = 0;
      const updatedTemplates = quickNavQuery.data.map((t) =>
        t.Id === variable.template.Id ? variable.template : t
      );
      updateQuickNavTemplatesCache(updatedTemplates, queryClient);
    },
  });
  // const { css, styles } = useStyles({ stylesFn });

  const handleOnChange = (row: BusinessProcessTemplateRestModel, value: boolean) => {
    if (value) {
      setActiveRow(row);
      SetVisible(true);
    } else {
      updateMutation.mutate({ template: row, newStatus: false });
    }
  };

  const handleAcknowlegment = () => {
    SetVisible(false);
    updateMutation.mutate({ template: activeRow, newStatus: true });
  };

  const columns: DataTableColumn<BusinessProcessTemplateRestModel>[] = [
    new SwitchDataColumn<BusinessProcessTemplateRestModel>({
      key: 'IsEnabled',
      header: formatMessage({ id: 'quickNav.configuration.columns.enable.header' }),
      onChange: handleOnChange,
      sortable: true,
      // .75 due to header length. If shorter, the sort option isn't clickable (It click the next header).
      flex: 0.75,
    }),
    new StringColumn<BusinessProcessTemplateRestModel>({
      key: 'DisplayName',
      header: formatMessage({ id: 'quickNav.configuration.columns.displayName.header' }),
      flex: 2,
      Cell: function ToDoNameCell({ row }) {
        // example usage - should open customer summary
        return (
          <>
            {row.IsEnabled ? (
              <Link small onClick={() => history.push(`/quicknav/configure/${row.Id}`)}>
                <HighlightFilterValue>{row.DisplayName}</HighlightFilterValue>
              </Link>
            ) : (
              <HighlightFilterValue>{row.DisplayName}</HighlightFilterValue>
            )}
          </>
        );
      },
    }),
    new StringColumn<BusinessProcessTemplateRestModel>({
      key: 'PublishedVariationsCount',
      header: formatMessage({ id: 'quickNav.configuration.columns.publishedInstances.header' }),
      flex: 2,
      getValue: (row) => row.PublishedVariationsCount.toString(),
    }),
    new StringColumn<BusinessProcessTemplateRestModel>({
      key: '',
      header: '',
      align: 'center',
      sortable: false,
      width: 100,
      padding: 'none',
      Cell: function ActionCell({ row, rowIndex }) {
        return (
          <Spacer paddingTop="tiny">
            <DropdownButtonProvider>
              <DropdownLinkButton
                disabled={row?.IsEnabled ? false : true}
                data-testid={`actionButton-${rowIndex}`}
                aria-label={`actionButton-${rowIndex}`}
              />
              <DropdownList>
                <DropdownListLinkItem
                  onClick={() =>
                    history.push(`/quicknav/configure/instance/${row.CurrentTemplateVersionId}`)
                  }
                >
                  {formatMessage({ id: 'quickNav.configuration.columns.actions.new' })}
                </DropdownListLinkItem>
                <DropdownListLinkItem onClick={() => history.push(`/quicknav/configure/${row.Id}`)}>
                  {formatMessage({ id: 'quickNav.configuration.columns.actions.view' })}
                </DropdownListLinkItem>
              </DropdownList>
            </DropdownButtonProvider>
          </Spacer>
        );
      },
    }),
  ];

  const tableState = useDataTable({
    columns,
    data: quickNavQuery.data || [],
    filtering: false,
    rowSelection: false,
    sorting: true,
    initialSortedColumnKey: 'DisplayName',
    initialSortDirection: 'asc',
    expandingRows: false,
    pagination: false,
    pageSizing: false,
  });
  const tableRows = useDataTableRows(tableState);

  return (
    // <div {...css(styles.layout)}>
    <DefaultPageLayout hideContentHeader={true}>
      <ContentHeader title={formatMessage({ id: 'quickNav.configuration.header' })} />
      <LocalizedDataTable
        {...tableState}
        {...tableRows}
        responsive={false}
        data-testid="dashboard-panel-datatable"
        isLoading={quickNavQuery.isLoading}
        emptyMessage={
          <Typography variant="disclaimer">
            <FormattedMessage id="common.error_loading_data" />
          </Typography>
        }
        rowAlignItems="stretch"
        rowVerticalAlign="middle"
      />
      {visible && (
        <Modal
          closable={true}
          variant="default"
          footer={
            <ButtonGroup endAlign={true}>
              <Button onClick={handleAcknowlegment} variant="primary">
                <FormattedMessage id="common.button.ok" />
              </Button>
            </ButtonGroup>
          }
          title=""
          onClose={() => SetVisible(false)}
        >
          <Typography variant="body">
            <FormattedMessage id="quickNav.configuration.enablePopup.content" />
          </Typography>
        </Modal>
      )}
    </DefaultPageLayout>
    // </div>
  );
};
export default withLayoutShell(QuickNavConfigurationMain);
