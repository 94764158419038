import {
  Badge,
  Column,
  Container,
  DropdownLinkButton,
  DropdownButtonProvider,
  Flex,
  Row,
  CheckBox,
  Spacer,
  DropdownList,
  DropdownListItem,
  Icon,
  Typography,
  Link,
} from 'alloy-foundation';
import React, { useEffect, useState } from 'react';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { useIntl } from 'react-intl';
import { useIsMutating } from '@tanstack/react-query';
import stylesFn from './styles';
import { BusinessObject3, ChecklistItemCompletionStatus, Task } from '../../../models/Process';
import { isPastDue } from '../../common/utils';
import { isDueToday } from './WorkItemCard';
import { errors } from './Errors';
import { useCommunicationService } from '../../communicationService/CommunicationServiceProvider';
import { useUpdateTaskStatus } from './useWorkQueue';

interface Props {
  task: Task;
  processId: string;
  businessObjects: BusinessObject3[];
}

const TASK_STATUS_UPDATE_KEY = 'Task_Status_Update';

const WorkItemTaskCard = ({ task, processId, businessObjects }: Props) => {
  const { css, styles } = useStyles({ stylesFn });
  const { formatMessage } = useIntl();
  const { showForm } = useCommunicationService();
  const [taskCompleted, setTaskCompleted] = useState(
    task.Status === ChecklistItemCompletionStatus.Completed
  );
  const mutationKey = [TASK_STATUS_UPDATE_KEY, task.Id];
  const isMutating = useIsMutating({ mutationKey }) > 0;
  const updateMutation = useUpdateTaskStatus(mutationKey, () =>
    setTaskCompleted(task.Status === ChecklistItemCompletionStatus.Completed)
  );

  const getAttributeValue = (attribute) => attribute.Value ?? attribute;

  const handleUpdateStatus = (status: ChecklistItemCompletionStatus) => {
    updateMutation.mutate({ Id: task.Id, processId, status });
  };

  const handleOpenStepAction = () => {
    if (task.Definition?.IntegrationViewClass === 'ManualTask') handleOpenUrl();
    else handleOpenWinform();
  };

  const handleOpenUrl = () => window.open(task.Definition?.Url);

  const handleOpenWinform = () => {
    const taskDefinition = task.Definition;
    const taskId = task.Id;
    const winformInit = taskDefinition?.IntegrationDefinition?.winformInit;
    const requiredBusinessObjects = winformInit?.requiredBos;
    const additionalKeyValues = winformInit?.additionalAttribs;
    const formName = winformInit.formName;

    if (businessObjects === null && requiredBusinessObjects?.length > 0)
      throw new Error(errors.nullPayload);

    let launchObject = { TaskId: taskId, ...additionalKeyValues };

    for (const reqBusObj of requiredBusinessObjects) {
      if (businessObjects === undefined) throw new Error(errors.emptyPayload);

      const foundBo = businessObjects?.find((p) => p.Index === reqBusObj.Index);

      if (!foundBo) throw new Error(errors.itemMissing);

      for (const attr of reqBusObj.Attributes ?? []) {
        const sourceAttr = foundBo.Attributes[attr.inKey];
        if (sourceAttr) {
          launchObject = {
            [`${attr.outKey}`]: getAttributeValue(sourceAttr),
            ...launchObject,
          };
        } else throw new Error(`${errors.missingItem}${attr}`);
      }
      for (const attr of reqBusObj.MetaAttributes ?? []) {
        const sourceMetaAttr = foundBo[attr.inKey];
        if (sourceMetaAttr) {
          launchObject = {
            [`${attr.outKey}`]: getAttributeValue(sourceMetaAttr),
            ...launchObject,
          };
        } else throw new Error(`${errors.missingItem}${attr}`);
      }
    }

    showForm(formName, launchObject);
  };

  useEffect(
    () => setTaskCompleted(task.Status === ChecklistItemCompletionStatus.Completed),
    [task.Status]
  );

  return (
    <div {...css(styles.taskCard)}>
      <Container fluid={true} noPadding={true}>
        <Spacer pr="xsmall" pl="medium">
          <Row>
            <Flex flexDirection="row" alignItems="center" id="flex-row">
              <Column>
                <Flex alignItems="center" height="52px">
                  <Flex justifyContent="flex-start" alignItems="center" height="52px">
                    <div style={{ width: '25px' }}>
                      <CheckBox
                        data-testid="step-checkbox"
                        onDark
                        renderErrorMessage={false}
                        disabled={
                          isMutating ||
                          !task.IsAvailable ||
                          task.Definition?.IsAutoCompleted ||
                          task.Status !== ChecklistItemCompletionStatus.NotCompleted
                        }
                        wrapLabel={false}
                        onChange={(checked) => {
                          setTaskCompleted(checked);
                          if (checked) handleUpdateStatus(ChecklistItemCompletionStatus.Completed);
                        }}
                        checked={taskCompleted}
                      />
                    </div>
                    <div style={{ width: '275px' }}>
                      {!task.IsAvailable ||
                      task.Status !== ChecklistItemCompletionStatus.NotCompleted ? (
                        <Typography variant="disclaimer" color="disabled" noWrap>
                          {task.Definition?.Title}
                        </Typography>
                      ) : (
                        <>
                          {task.Definition?.IntegrationViewClass === 'ManualTask' &&
                            !task.Definition?.Url && (
                              <Typography variant="bodySm" color="white" noWrap>
                                {task.Definition?.Title}
                              </Typography>
                            )}
                          {(task.Definition?.IntegrationViewClass === 'ams.Winform' ||
                            task.Definition?.Url) && (
                            <Link
                              truncated
                              block
                              variant="white"
                              typographyProps={{ variant: 'bodySm' }}
                              onClick={() => handleOpenStepAction()}
                            >
                              {task.Definition?.Title}
                            </Link>
                          )}
                        </>
                      )}
                    </div>
                  </Flex>
                </Flex>
              </Column>
              <Column>
                <Flex justifyContent="flex-end" alignItems="center" height="52px" width="134px">
                  {task.IsAvailable && (
                    <Badge
                      color={
                        task.Status !== ChecklistItemCompletionStatus.NotCompleted
                          ? 'grayDark'
                          : isPastDue(task.FormattedDueDate)
                          ? 'red'
                          : 'green'
                      }
                      fontVariant="bodySm"
                      semibold={false}
                    >
                      {task.Status !== ChecklistItemCompletionStatus.NotCompleted
                        ? task.FormattedCompletedDate
                        : isDueToday(task.DueDate)
                        ? formatMessage({ id: 'common.today.reduced' })
                        : task.FormattedDueDate}
                    </Badge>
                  )}
                  {task.IsAvailable ? (
                    <Spacer mr="small">
                      <DropdownButtonProvider>
                        <DropdownLinkButton
                          data-testid="card-actions"
                          disabled={
                            isMutating || task.Status !== ChecklistItemCompletionStatus.NotCompleted
                          }
                          aria-label="card-actions"
                        />
                        <DropdownList>
                          {(task.Definition?.IntegrationViewClass === 'ams.Winform' ||
                            (task.Definition?.IntegrationViewClass === 'ManualTask' &&
                              task.Definition.Url)) && (
                            <DropdownListItem onSelect={() => handleOpenStepAction()}>
                              {formatMessage({ id: 'drawer.workItem.launchStep' })}
                            </DropdownListItem>
                          )}
                          {task.Status === ChecklistItemCompletionStatus.NotCompleted &&
                            !task.Definition?.IsAutoCompleted && (
                              <DropdownListItem
                                onSelect={() => {
                                  setTaskCompleted(true);
                                  handleUpdateStatus(ChecklistItemCompletionStatus.Completed);
                                }}
                              >
                                {formatMessage({ id: 'drawer.workItem.completeStep' })}
                              </DropdownListItem>
                            )}
                          {task.Status === ChecklistItemCompletionStatus.NotCompleted &&
                            !task.Definition?.IsAutoCompleted &&
                            task.Definition?.IsExcludable && (
                              <DropdownListItem
                                onSelect={() =>
                                  handleUpdateStatus(ChecklistItemCompletionStatus.NotApplicable)
                                }
                              >
                                {formatMessage({ id: 'drawer.workItem.makeStepNotApplicable' })}
                              </DropdownListItem>
                            )}
                        </DropdownList>
                      </DropdownButtonProvider>
                    </Spacer>
                  ) : (
                    <Spacer pr="small" mr="small">
                      <Icon data-testid="step-lock" name="locked" color="disabled" />
                    </Spacer>
                  )}
                </Flex>
              </Column>
            </Flex>
          </Row>
        </Spacer>
      </Container>
    </div>
  );
};

export default WorkItemTaskCard;
