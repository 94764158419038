import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PingOnlyAuthProvider } from 'ui-auth-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './components/layout/index.css';
import 'bootstrap/dist/css/bootstrap-grid.css';
import { DefaultTheme } from 'alloy-foundation';
import WithStylesContext from 'react-with-styles/lib/WithStylesContext';
import AphroditeInterface from 'react-with-styles-interface-aphrodite';
import { IntlProvider } from 'react-intl';
import { LocaleContextProvider, useLocaleContext } from './components/localization/LocaleContext';
import App from './components/layout/App';
import BottomActionBarProvider from './components/common/application-layout/BottomActionBarProvider';
import { DrawerProvider } from './components/drawer/DrawerProvider';
import { Locale } from './components/localization/Locale';
import en from './translations/en/lang';
import fr from './translations/fr/lang';
import { LogFormProvider } from './components/common/providers/LogFormProvider';
import { ProductInfoProvider } from './components/productInfo/ProductInfoProvider';

const queryClient = new QueryClient();
const InnerIntlProvider = ({ children }) => {
  const { locale } = useLocaleContext();
  const messages = {
    [`${Locale.EnCa}`]: en,
    [`${Locale.FrCa}`]: fr,
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]} defaultLocale="en-CA">
      {children}
    </IntlProvider>
  );
};

const container = document.getElementById('root');
// React 18 upgrade documentation (https://react.dev/blog/2022/03/08/react-18-upgrade-guide)
// says to do this if using typescript:
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// Unfortunately, this violates an eslint rule
// eslint-disable-next-line
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <BrowserRouter basename="/">
    <WithStylesContext.Provider
      value={{
        stylesInterface: AphroditeInterface,
        stylesTheme: DefaultTheme,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
          <PingOnlyAuthProvider>
            <LocaleContextProvider>
              <InnerIntlProvider>
                <BottomActionBarProvider>
                  <DrawerProvider>
                    <ProductInfoProvider>
                      <LogFormProvider>
                        <App />
                      </LogFormProvider>
                    </ProductInfoProvider>
                  </DrawerProvider>
                </BottomActionBarProvider>
              </InnerIntlProvider>
            </LocaleContextProvider>
          </PingOnlyAuthProvider>
        </DndProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </WithStylesContext.Provider>
  </BrowserRouter>
  // </React.StrictMode>
);
