import { ErrorPage, ErrorPage403, ErrorPage500 } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';
import { useProductInfoProvider } from '../../productInfo/ProductInfoProvider';

export const HTTP_STATUS = {
  INTERNAL_SERVER_ERROR: 500,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};
const LoginError = () => {
  const { formatMessage } = useIntl();
  const { loginError } = useProductInfoProvider();
  if (loginError) {
    switch (loginError.errorCode) {
      case HTTP_STATUS.UNAUTHORIZED:
      case HTTP_STATUS.FORBIDDEN:
        return (
          <React.Fragment>
            {loginError.errorDetail === '2' ? (
              <ErrorPage403
                header={formatMessage({ id: 'errorPages.common.header' })}
                description={formatMessage({ id: 'errorPages.403.sigpermission.message' })}
                subHeading={formatMessage({ id: 'errorPages.403.error' })}
              />
            ) : (
              <ErrorPage403
                header={formatMessage({ id: 'errorPages.common.header' })}
                description={formatMessage({ id: 'errorPages.403.license.message' })}
                subHeading={formatMessage({ id: 'errorPages.403.error' })}
              />
            )}
          </React.Fragment>
        );
      case HTTP_STATUS.INTERNAL_SERVER_ERROR:
        return (
          <ErrorPage500
            header={formatMessage({ id: 'errorPages.500.header' })}
            description={formatMessage({ id: 'errorPages.500.message' })}
            subHeading={formatMessage({ id: 'errorPages.500.error' })}
          />
        );
      default:
        <ErrorPage
          header={formatMessage({ id: 'errorPages.common.header' })}
          description={formatMessage({ id: 'errorPages.generic.message' })}
        />;
    }
  }
  return (
    <ErrorPage
      header={formatMessage({ id: 'errorPages.common.header' })}
      description={formatMessage({ id: 'errorPages.generic.message' })}
    />
  );
};

export default LoginError;
