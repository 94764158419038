import React, { useState, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { LocalContextType } from './LocaleContextType';
import { Locale } from './Locale';
import applicationStateService from '../../services/applicationState/applicationStateService';

// const stateCollection = productInfoService.getStateCollection();
// default to en-CA if language isn't explicitely set to french

export const LocaleContext = React.createContext<LocalContextType>({
  locale: Locale.EnCa,
  setCulture: (locale) => locale,
  setInitialLocale: (p) => p,
});
export const LocaleContextProvider = ({ children }) => {
  const toLocaleEnum = (value: any): Locale =>
    value?.toLowerCase() === 'fr-ca' ? Locale.FrCa : Locale.EnCa;

  const [locale, setLocale] = useState(Locale.EnCa);
  const languageMutation = useMutation(
    (body: any) => applicationStateService.updateAppState(body),
    {
      onSuccess: (result, body) => {
        setLocale(body?.culture?.code);
        window.location.reload();
      },
    }
  );

  const setInitialLocale = (code: string) => {
    setLocale(toLocaleEnum(code));
  };

  const setCulture = (arg: Locale) => {
    languageMutation.mutate({ culture: { code: arg } });
  };

  return (
    <LocaleContext.Provider value={{ locale, setCulture, setInitialLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocaleContext = () => {
  const context = useContext(LocaleContext);
  if (context === undefined) {
    throw new Error('useLocaleContext cannot be called from outside of a LocaleContextProvider');
  }
  return context;
};
