import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { LogFormServices } from '../../../services/logForm/LogFormService';
import { LogFormSuspenseExtData } from '../../../models/LogFormData';

export const useLogFormSuspenseExt = (
  suspid: string,
  option?: UseQueryOptions<LogFormSuspenseExtData, unknown, LogFormSuspenseExtData, QueryKey>
): UseQueryResult<LogFormSuspenseExtData> => {
  const suspenseext = useQuery<LogFormSuspenseExtData>(
    ['customer-suspense-ext', suspid],
    () => LogFormServices.getsuspext(suspid),
    option
  );
  return suspenseext;
};
