import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ProductInfo } from '../../models/ProductInfo';
import productInfoService from '../../services/productInfo/productInfoService';

export const useAgenciesData = (
  options?: UseQueryOptions<ProductInfo, unknown, ProductInfo, QueryKey>
) => {
  const useAgencies = useQuery<ProductInfo>(
    ['get-agencies'],
    () => productInfoService.retrieveAgencies(),
    options
  );

  return useAgencies;
};
