import basePSSRequests from '../../api/basePSSRequests';
import {
  LogFormData,
  LogFormActivityExtData,
  LogFormActivityExt,
  LogFormSuspenseExtData,
} from '../../models/LogFormData';

// base url
const baseURL = '/logform/CreateLogForm';
const baseextURL = '/logform/';
function uint8ToBase64(uint8Array) {
  const CHUNK_SIZE = 0x8000;
  let result = '';
  for (let i = 0; i < uint8Array.length; i += CHUNK_SIZE) {
    result += String.fromCharCode.apply(null, uint8Array.subarray(i, i + CHUNK_SIZE));
  }
  return btoa(result);
}
// exports
export const LogFormServices = {
  create: async (logFormData: LogFormData): Promise<string> => {
    if (logFormData.file) {
      const file = logFormData.file as File;
      const arrayBuffer = await file.arrayBuffer();
      const byteArray = new Uint8Array(arrayBuffer);
      const base64String = uint8ToBase64(byteArray);
      const payload = {
        ...logFormData,
        file: base64String,
        filename: file.name,
      };

      const jsonData = JSON.stringify(payload);
      return await basePSSRequests.post(baseURL, jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } else {
      const jsonData = JSON.stringify(logFormData);
      return await basePSSRequests.post(baseURL, jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  },
  getext: (actid: string): Promise<LogFormActivityExtData> =>
    basePSSRequests.get(`${baseextURL}/${actid}/getactivityext`).then((response) => response),
  createext: (logFormActivityExt: LogFormActivityExt): Promise<string> =>
    basePSSRequests
      .post(`${baseextURL}/createactivityext`, logFormActivityExt)
      .then((response) => response),
  getsuspext: (suspid: string): Promise<LogFormSuspenseExtData> =>
    basePSSRequests.get(`${baseextURL}/${suspid}/getsuspenseext`).then((response) => response),
  getFile: (activityId: string, fileName: string): Promise<Blob> =>
    basePSSRequests
      .get(`${baseextURL}/${activityId}/getfile`, {
        responseType: 'blob',
      })
      .then((response) => {
        return response;
      }),
};

export default LogFormServices;
