import { useMutation } from '@tanstack/react-query';
import { LogFormServices } from '../../../services/logForm/LogFormService';

export const useGetFile = (onErr: () => void) => {
  return useMutation(
    ({ id, fileName }: { id: string; fileName: string }) => LogFormServices.getFile(id, fileName),
    {
      onSuccess: (blob, { fileName }) => {
        const url = window?.URL?.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
      onError: () => {
        onErr();
      },
    }
  );
};
