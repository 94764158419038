import {
  HashLink,
  SideMenu,
  SideMenuExpandedCategoryListV2,
  SideMenuExpandedCategoryListLinkV2,
  Spacer,
} from 'alloy-foundation';
// import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MY_PROFILE_SCROLL_SECTIONS } from './MyProfileScrollSections';
import { TopNavHeight } from '../topnav/TopNavConstants';

interface Props {
  activeSection: string;
}

const MyProfileSideMenu = ({ activeSection }: Props) => {
  return (
    <SideMenu
      scrollable
      variant="light"
      scrollableHeight={`calc(100vh - ${TopNavHeight})`}
      data-testid="my-profile-side-menu"
    >
      <Spacer pt="xxxlarge" />
      <Spacer pt="small" />
      <SideMenuExpandedCategoryListV2>
        <SideMenuExpandedCategoryListLinkV2
          as={HashLink}
          active={activeSection === MY_PROFILE_SCROLL_SECTIONS.GENERAL_INFO_SECTION_ID}
          to={`#${MY_PROFILE_SCROLL_SECTIONS.GENERAL_INFO_SECTION_ID}`}
        >
          <FormattedMessage id="myProfile.generalInfo.header" />
        </SideMenuExpandedCategoryListLinkV2>
        <SideMenuExpandedCategoryListLinkV2
          as={HashLink}
          active={activeSection === MY_PROFILE_SCROLL_SECTIONS.UPDATE_PASSWORD_SECTION_ID}
          to={`#${MY_PROFILE_SCROLL_SECTIONS.UPDATE_PASSWORD_SECTION_ID}`}
        >
          <FormattedMessage id="myProfile.updatePassword.header" />
        </SideMenuExpandedCategoryListLinkV2>
        <SideMenuExpandedCategoryListLinkV2
          as={HashLink}
          active={activeSection === MY_PROFILE_SCROLL_SECTIONS.BROKERAGE_INFO_SECTION_ID}
          to={`#${MY_PROFILE_SCROLL_SECTIONS.BROKERAGE_INFO_SECTION_ID}`}
        >
          <FormattedMessage id="myProfile.brokerageInfo.header" />
        </SideMenuExpandedCategoryListLinkV2>
      </SideMenuExpandedCategoryListV2>
    </SideMenu>
  );
};

export default MyProfileSideMenu;
