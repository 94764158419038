import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  MutationOptions,
  useMutation,
} from '@tanstack/react-query';
import { CustomerDropdowns } from '../../../models/CustomerDropdowns';
import { PssCustomer } from '../../../models/PssCustomer';
import { SigPermissions } from '../../../models/SigPermissions';
import { PutCustomerModel } from '../../../models/putPayloads/PutCustomerModel';
import customerServices from '../../../services/customer/Customers';

export const useCustomer = (
  queryKey: string,
  options?: UseQueryOptions<PssCustomer, unknown, PssCustomer, QueryKey>
) => {
  const useCustomerQuery = useQuery<PssCustomer>(
    [queryKey],
    () => customerServices.get(queryKey),
    options
  );

  return useCustomerQuery;
};

export interface useUpdateCustomerProps {
  original: PutCustomerModel;
  updated: PutCustomerModel;
  sigPermissions: SigPermissions;
}

export const useUpdateCustomer = (
  customerId: string,
  dropdowns: CustomerDropdowns,
  options: MutationOptions<useUpdateCustomerProps, unknown, useUpdateCustomerProps, any>
) => {
  const customerMutation = useMutation<useUpdateCustomerProps, unknown, any>(
    (data) =>
      customerServices.put(customerId, data.original, data.updated, dropdowns, data.sigPermissions),
    options
  );

  return customerMutation;
};
