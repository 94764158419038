import { TopNav as AlloyTopNav } from 'alloy-foundation';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TopNavAgency from './TopNavAgency';
import TopNavSelectAgency from './TopNavSelectAgency';

const TopNav = () => {
  return (
    <React.Fragment>
      <AlloyTopNav variant="dark">
        <Switch>
          <Route path="/Login/SelectAgency" component={TopNavSelectAgency} />
          <Route path="/Login/Error" component={TopNavSelectAgency} />
          <Route component={TopNavAgency} />
        </Switch>
      </AlloyTopNav>
    </React.Fragment>
  );
};

export default TopNav;
