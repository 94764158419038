import React from 'react';
import { Column, Divider, FormCheckBox, FormInput, Group, Row, Spacer } from 'alloy-foundation';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import CurrentAddress from '../../details/currentAddress/CurrentAddress';
import * as FieldNames from '../../fieldNames';
import { SectionInfoProps } from '../SectionInfoProps';
import { PhoneNumber } from '../../../../models/PhoneNumber';
import EmailForm from '../../details/emailsInformation/EmailForms';
import { Emails } from '../../../../models/Emails';
import PreferedContactMethod from '../../details/preferedContactMethod/PreferedContactMethod';

export const ContactInformationEditForm = (props: SectionInfoProps) => {
  const { formatMessage } = useIntl();
  const prefix = props.namespace ? `${props.namespace}.` : '';
  const formikContext = useFormikContext();

  async function resetPhoneProperties(namespace: string) {
    await formikContext.setFieldValue(`${prefix}${namespace}.${FieldNames.PHONE_NUMBER}`, '');
    await formikContext.setFieldValue(`${prefix}${namespace}.${FieldNames.PHONE_EXTENSION}`, '');
  }

  // Setup event handlers
  const handleNotApplicableChange = async (namespace: string) => {
    await formikContext.handleChange(`${prefix}${namespace}.${FieldNames.NOT_APPLICABLE}`);

    const previousValue = formikContext.getFieldProps(
      `${prefix}${namespace}.${FieldNames.NOT_APPLICABLE}`
    ).value as boolean;

    if (!previousValue) {
      await resetPhoneProperties(namespace);
    }
  };

  const phoneForm = (phone: PhoneNumber, index: number) => {
    const isMandatory = props.customerDropdown.data.MandatoryContactMethods.includes(phone.type);
    const label = props.customerDropdown.data.TypeOfPhoneNumbers.find(
      (t) => t.Key.toString() === phone.type
    ).Value;
    const namespace = `${prefix}${FieldNames.PHONES}[${index}]`;
    const currentValue = formikContext?.getFieldProps(namespace).value as PhoneNumber;
    return (
      <React.Fragment key={index}>
        <Column md={3}>
          <Group stacked={true} spacing="tiny">
            <FormInput
              name={`${namespace}.${FieldNames.PHONE_NUMBER}`}
              format="phone"
              placeholder="(555) 555-5555"
              label={label}
              disabled={currentValue?.notApplicable ?? false}
              required={isMandatory}
              noSpacing={true}
            />
            {isMandatory && (
              <FormCheckBox
                name={`${namespace}.${FieldNames.NOT_APPLICABLE}`}
                onChange={() => handleNotApplicableChange(namespace)}
              >
                {formatMessage({ id: 'customer.contactMethods.notApplicable' })}
              </FormCheckBox>
            )}
          </Group>
        </Column>
        {currentValue.type === '1' && (
          <React.Fragment>
            <Column md={2}>
              <FormInput
                name={`${namespace}.${FieldNames.PHONE_EXTENSION}`}
                format="numberNoCommas"
                placeholder="5555"
                maxLength={5}
                label={formatMessage({ id: 'customer.contactMethods.phoneNumberExtension' })}
                disabled={currentValue?.notApplicable ?? false}
                required={false}
              />
            </Column>
            <Column md={1}>
              <div />
            </Column>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const phones = () => {
    const rows = [];
    const p = formikContext.getFieldProps(`${prefix}${FieldNames.PHONES}`).value as PhoneNumber[];
    p.map((c, i) => rows.push(phoneForm(c, i)));
    return rows;
  };

  const emails = () => {
    const rows = [];
    const p = formikContext.getFieldProps(`${prefix}${FieldNames.EMAILS}`).value as Emails[];
    p.map((c, i) =>
      rows.push(
        <React.Fragment key={i}>
          <Row>
            <EmailForm
              namespace={`${prefix}${FieldNames.EMAILS}`}
              index={i}
              dropdowns={props.customerDropdown.data}
              hideType={true}
            />
          </Row>
        </React.Fragment>
      )
    );
    return rows;
  };

  return (
    <React.Fragment>
      <CurrentAddress
        dropdowns={props.customerDropdown?.data}
        showHeader={false}
        parentNamespace={props.namespace}
      ></CurrentAddress>
      <Spacer paddingBottom="small">
        <div></div>
      </Spacer>
      <Spacer mr="xlarge" mb="tiny">
        <Row>{phones()}</Row>
      </Spacer>
      <Divider pt="none" pb="small" ml="none" mr="none" />
      <Spacer mr="xlarge" mb="tiny">
        {emails()}
      </Spacer>
      <Divider pt="none" pb="small" ml="none" mr="none" />
      <PreferedContactMethod
        parentNamespace={props.namespace}
        dropdowns={props.customerDropdown.data}
        hideHeader={true}
      />
    </React.Fragment>
  );
};
