import React from 'react';
import {
  Typography,
  LoadingIndicator,
  Container,
  Row,
  Column,
  Spacer,
  FormField,
  Group,
} from 'alloy-foundation';
import { UseQueryResult } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { PssCustomer } from '../../../../models/PssCustomer';
import { ContactMethod } from '../../../../models/ContactMethod';
import { useFormatting } from '../../../common/hooks/useFormatting';

const ContactInformationViewOnly = ({
  customer,
}: {
  customer: UseQueryResult<PssCustomer, unknown>;
}) => {
  const { formatMessage } = useIntl();
  const { formatPhoneNumber } = useFormatting();

  const contactMethodToHtml = (
    title: string,
    contactMethod: ContactMethod,
    isPhoneNumber = true
  ) => {
    if (!contactMethod || contactMethod.IsNA || (contactMethod.Details ?? '') === '') {
      return <React.Fragment />;
    }

    let details = contactMethod.Details;

    if (isPhoneNumber) {
      details = formatPhoneNumber(
        contactMethod.AreaCode,
        contactMethod.Details,
        contactMethod.Extension
      );
    }

    return (
      <Column md={3}>
        <Spacer mb="none">
          <FormField label={formatMessage({ id: `contactMethods.${title}` })}>
            <Typography
              wordBreak="break-all"
              variant="body"
              data-testid={`CustomerSummary-ContactMethods-details-${title}`}
            >
              {details}
            </Typography>
          </FormField>
        </Spacer>
      </Column>
    );
  };

  return customer.isLoading ? (
    <LoadingIndicator />
  ) : (
    <Container noPadding={true} data-testid="ContactMethods">
      <Row>
        <Column md={3}>
          <Spacer mb="none">
            <FormField label={formatMessage({ id: 'customer.details.address.title' })}>
              <Group stacked={true} spacing="tiny">
                <Typography variant="body" data-testid="CustomerSummary-CurrentAddress-Details1">
                  {customer.data?.AddressLine1}
                </Typography>
                <Typography variant="body" data-testid="CustomerSummary-CurrentAddress-Details1">
                  {customer.data?.AddressLine2}
                </Typography>
                <Typography variant="body" data-testid="CustomerSummary-CurrentAddress-Details1">
                  {`${customer.data?.City}, ${customer.data?.State} ${customer.data?.ZipCode}`}
                </Typography>
              </Group>
            </FormField>
          </Spacer>
        </Column>

        {contactMethodToHtml('ResidentialPhone', customer.data?.ContactMethods['HomePhone'])}
        {contactMethodToHtml('BusinessPhone', customer.data?.ContactMethods['BusinessPhone'])}
        {contactMethodToHtml('CellPhone', customer.data?.ContactMethods['CellPhone'])}

        {contactMethodToHtml(
          'PersonalEmail',
          customer.data?.ContactMethods['PersonalEmail'],
          false
        )}
        {contactMethodToHtml('WorkEmail', customer.data?.ContactMethods['WorkEmail'], false)}
        {contactMethodToHtml('Website', customer.data?.ContactMethods['Website'], false)}

        {contactMethodToHtml('FaxNumber', customer.data?.ContactMethods['Fax'])}
        {contactMethodToHtml('EmergencyPhone', customer.data?.ContactMethods['EmergencyPhone'])}
        {contactMethodToHtml('SeasonalNumber', customer.data?.ContactMethods['SeasonalPhone'])}
        {contactMethodToHtml('Text', customer.data?.ContactMethods['TextPhone'])}

        {(customer.data?.PreferredContactMethodName ?? '') !== '' && (
          <Column md={3}>
            <Spacer mb="none">
              <FormField
                label={formatMessage({ id: 'customer.contactMethods.preferedContactMethod' })}
              >
                <Typography
                  variant="body"
                  data-testid="CustomerSummary-ContactMethods-PreferredCM-details"
                >
                  {customer.data?.PreferredContactMethodName}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
        )}
      </Row>
    </Container>
  );
};

export default ContactInformationViewOnly;
