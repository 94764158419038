import {
  Button,
  Column,
  CheckBox,
  Row,
  Typography,
  FormField,
  Divider,
  Link,
  TextArea,
  Spacer,
  Flex,
  ButtonGroup,
  useAppContext,
} from 'alloy-foundation';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash';
import { ActivityReduced } from '../../../models/ActivitySummary';
import { LogFormActivityExt } from '../../../models/LogFormData';
import { useFormatting } from '../../common/hooks/useFormatting';
import * as fieldNames from './fieldNames';
import LogFormServices from '../../../services/logForm/LogFormService';
import { useGetFile } from './useLogFormActivityFile';

type Props = {
  activity: ActivityReduced;
  bCanEditActivity: boolean;
  logformactivityextdata: LogFormActivityExt[];
  setLogFormViewVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const LogFormViewSummary = ({
  activity,
  bCanEditActivity,
  logformactivityextdata,
  setLogFormViewVisible,
}: Props) => {
  const { formatMessage, locale } = useIntl();
  const { formatDate } = useFormatting();
  const { formatCurrency } = useFormatting();
  const [activitySummary, setActivitySummary] = useState('');
  const [isShared, setIsShared] = useState(activity?.IsShared === 'Y');
  const [curActivitySummary, setCurActivitySummary] = useState('');
  const [bSummaryEdit, setSummaryEdit] = useState(false);
  const [bDisableLink, setDisableLink] = useState(true);
  const { addErrorToast, addSuccessToast } = useAppContext();

  const onFileDownloadError = () => {
    addErrorToast(<FormattedMessage id="error.toasts.fileDownwloadError" />, {
      title: <FormattedMessage id="error.toasts.header" />,
      withBottomActionBar: true,
    });
  };

  const { mutate: getFile, isLoading: isDownloadingFile } = useGetFile(onFileDownloadError);
  const handleSummaryEdit = () => {
    if (bDisableLink) {
      setSummaryEdit(!bSummaryEdit);
      setDisableLink(false);
    }
  };
  const handleSummaryChange = (event) => {
    setActivitySummary(event);
  };
  const handleIsShared = () => {
    setIsShared(!isShared);
  };

  const resetForm = () => {
    setDisableLink(true);
    setSummaryEdit(!bSummaryEdit);
  };

  const premiumValue = activity?.Premium ? parseInt(activity?.Premium, 10) : 0;

  const handleDownloadClick = (id: string, docName: string) => {
    getFile({ id, fileName: docName });
  };

  // to show summary properly
  useEffect(() => {
    let temp = '';
    if (logformactivityextdata) {
      logformactivityextdata?.forEach((ext) => {
        if (ext.Comment1.length !== 0) {
          if (logformactivityextdata.length !== 1) {
            temp = `${temp} \n ${ext.ShortName}`;
            temp = `${temp} \n ${ext.Comment1}`;
            temp = `${temp} \n -------`;
          } else temp = `${ext.Comment1}`;
        }
      });
    }
    setCurActivitySummary(temp);
  }, [logformactivityextdata, setCurActivitySummary]);

  const createActivityExt = async () => {
    const logFormExt = {
      TranId: activity.ActivityId,
      Comment1: activitySummary,
    } as LogFormActivityExt;
    return await LogFormServices.createext(logFormExt);
  };
  const queryClient = useQueryClient();

  const mutation = useMutation(createActivityExt, {
    onError: (error, variables, context) => {
      addErrorToast(<FormattedMessage id="logformviewsummary.activityextCreateError" />, {
        title: <FormattedMessage id="error.toasts.header" />,
        withBottomActionBar: true,
      });
    },
    onSuccess: (data, variables, context) => {
      data === null || data === undefined
        ? addErrorToast(<FormattedMessage id="logformviewsummary.activityextCreateError" />, {
            title: <FormattedMessage id="error.toasts.header" />,
            withBottomActionBar: true,
          })
        : addSuccessToast(<FormattedMessage id="logformviewsummary.activityextCreateSuccess" />, {
            title: <FormattedMessage id="success.toasts.header" />,
            withBottomActionBar: true,
          });
      queryClient.invalidateQueries(['activity']);
      setLogFormViewVisible(false);
    },
  });

  return (
    <React.Fragment>
      <Divider pb="small" pt="small" paddingTop="none" />
      <Row>
        <Column xs={12} sm={12} md={4}>
          <FormField label={formatMessage({ id: 'logform.saleStage' })}>
            <Typography variant="body">{activity?.SalesStage}</Typography>
          </FormField>
        </Column>
        <Column xs={12} sm={12} md={4}>
          <FormField label={formatMessage({ id: 'logform.premium' })}>
            <Typography variant="body">{formatCurrency(premiumValue, 0, locale)}</Typography>
          </FormField>
        </Column>
        <Column xs={12} sm={12} md={4}>
          <FormField label={formatMessage({ id: 'logform.closeDate' })}>
            <Typography variant="body">{formatDate(activity?.CloseDate)}</Typography>
          </FormField>
        </Column>
      </Row>
      <Divider pb="small" pt="small" paddingTop="none" />
      <Row>
        <Column xs={12} sm={12} md={4}>
          <FormField label={formatMessage({ id: 'logform.activityType' })}>
            <Typography variant="body">{activity?.Activity}</Typography>
          </FormField>
        </Column>
        <Column xs={12} sm={12} md={4}>
          <FormField label={formatMessage({ id: 'logform.descriptionType' })}>
            <Typography variant="body">{activity?.Description}</Typography>
          </FormField>
        </Column>
      </Row>
      <Divider pb="small" pt="small" paddingTop="none" />
      <Row>
        <Column xs={12} sm={12} md={2}>
          <Flex alignItems="center">
            <Typography variant="h5">{formatMessage({ id: 'logformview.attachdoc' })}</Typography>
          </Flex>
        </Column>
      </Row>
      <Row>
        <Column>
          {activity.HasDocument && (
            <Spacer pl="small" pb="tiny">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  if (!isDownloadingFile) {
                    handleDownloadClick(activity.ActivityId, activity.DocName);
                  }
                }}
                disabled={isDownloadingFile}
                style={{ pointerEvents: isDownloadingFile ? 'none' : 'auto' }}
              >
                {activity.DocName}
              </Link>
            </Spacer>
          )}
          <Flex alignItems="center">
            <Button icon="addNew" iconAlign="left" size="small" disabled>
              <FormattedMessage id="logformview.uploaddocument" />
            </Button>
            <Spacer pl="small" pt="medium">
              <CheckBox checked={isShared} onChange={handleIsShared}>
                {formatMessage({ id: 'logformview.sharetoinsurlink' })}
              </CheckBox>
            </Spacer>
          </Flex>
        </Column>
      </Row>
      <Row>
        <Column xs={12} sm={12} md={10}>
          <Typography variant="h5">{formatMessage({ id: 'logform.summary' })}</Typography>
        </Column>
        <Column xs={12} sm={12} md={2}>
          <div
            style={{
              textAlign: 'right',
              float: 'right',
            }}
          >
            <Link
              onClick={handleSummaryEdit}
              disabled={!bCanEditActivity || activity.IsSystemActivity}
            >
              {formatMessage({ id: 'logformview.EditSummary' })}
            </Link>
          </div>
        </Column>
      </Row>
      {bSummaryEdit && (
        <Row>
          <Column xs={12} sm={12} md={12}>
            <TextArea
              name={fieldNames.SUMMARY}
              label={formatMessage({ id: 'logform.summary' })}
              value={activitySummary}
              hideLabel={true}
              placeholder={formatMessage({ id: 'logform.typesomething' })}
              autoResize={true}
              maxLength={8000}
              onChange={handleSummaryChange}
            />
          </Column>
        </Row>
      )}
      {bSummaryEdit && (
        <Row>
          <Column xs={12} sm={12} md={12}>
            <ButtonGroup endAlign={true}>
              <Button variant="tertiary" onClick={() => mutation.mutateAsync()}>
                <FormattedMessage id="logform.savelog" />
              </Button>
              <Button variant="tertiary" onClick={() => resetForm()}>
                <FormattedMessage id="common.button.cancel" />
              </Button>
            </ButtonGroup>
          </Column>
        </Row>
      )}
      <Row>
        <Column xs={12} sm={12} md={12}>
          <TextArea
            disabled={true}
            hideLabel={true}
            label={formatMessage({ id: 'logform.summary' })}
            value={curActivitySummary}
            onChange={noop}
            autoResize={true}
            maxLength={8000}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

export default LogFormViewSummary;
