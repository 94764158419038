import { Column, FormRadioButtonController, RadioButton, Row, Spacer } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';
import { CustomerDropdowns } from '../../../../../models/CustomerDropdowns';
import { useTypeOfBusiness } from '../../../TypeOfBusinessProvider';
import { TYPE_OF_BUSINESS } from '../../../fieldNames';
import Header from '../../../../common/header/Header';

export const TypeOfBusiness: React.FC<{ dropdowns: CustomerDropdowns }> = ({ dropdowns }) => {
  const { formatMessage } = useIntl();
  const { setTypeOfBusiness } = useTypeOfBusiness();
  return (
    <>
      <Header
        title={formatMessage({ id: 'customer.details.businessInformation.typeOfBusiness' })}
        topSpacer={false}
      />
      <Spacer mr="xlarge" mb="tiny">
        <Row>
          <Column md={2}>
            <FormRadioButtonController
              name={TYPE_OF_BUSINESS}
              label=""
              onChange={(value) => setTypeOfBusiness(value)}
              direction="row"
            >
              <RadioButton data-testid="TypeofBusiness-Commercial" value="C">
                {formatMessage({
                  id: 'customer.details.businessInformation.typeOfBusiness.Commercial',
                })}
              </RadioButton>
              <RadioButton data-testid="TypeofBusiness-Personal" value="P">
                {formatMessage({
                  id: 'customer.details.businessInformation.typeOfBusiness.Personal',
                })}
              </RadioButton>
            </FormRadioButtonController>
          </Column>
        </Row>
      </Spacer>
    </>
  );
};
