import {
  Column,
  FileInput,
  Flex,
  FormCheckBox,
  Link,
  Row,
  Spacer,
  TextArea,
  Typography,
} from 'alloy-foundation';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import * as fieldNames from './fieldNames';
import { LogFormSuspenseExtData } from '../../../models/LogFormData';
import { useProductInfoProvider } from '../../productInfo/ProductInfoProvider';

type LogFormSummaryProps = {
  suspenseId: string;
  logformsuspenseextdata: LogFormSuspenseExtData;
  logFormType?: string;
};

const LogFormSummary = ({
  suspenseId,
  logformsuspenseextdata,
  logFormType,
}: LogFormSummaryProps) => {
  const { formatMessage } = useIntl();
  const { setFieldValue } = useFormikContext();
  const [bSummaryEdit, setSummaryEdit] = useState(false);
  const [bCanEditSuspense, setCanEditSuspense] = useState(false);
  const [suspenseSummary, setSuspenseSummary] = useState('');
  const [extraSuspenseSummary, setextraSuspenseSummary] = useState('');
  const [invalidForm, setInvalidForm] = useState(false);
  const bCreating = suspenseId === '';
  const { getMaxDocSize } = useProductInfoProvider();
  const MAX_FILE_SIZE = getMaxDocSize() * 10 * 1024 * 1024;

  const handleSummaryEdit = () => {
    if (bCanEditSuspense) {
      setSummaryEdit(!bSummaryEdit);
      setCanEditSuspense(false);
    }
  };
  const handleSummaryChange = (event) => {
    setextraSuspenseSummary(event);
    setFieldValue(fieldNames.SUMMARY, event);
  };

  const handleExtraSummaryChange = (event) => {
    setSuspenseSummary(event);
    setFieldValue(fieldNames.EXTRASUMMARY, event);
  };
  const handleFileChange = (files: File[], event?: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files[0];
    if (file?.size > MAX_FILE_SIZE) {
      setInvalidForm(true);
    } else {
      setInvalidForm(false);
      setFieldValue(fieldNames.FILE, file);
    }
  };

  useEffect(() => {
    let temp = '';
    if (logformsuspenseextdata) {
      if (logformsuspenseextdata?.SuspenseExtData.length !== 0) {
        logformsuspenseextdata?.SuspenseExtData.forEach((ext) => {
          if (ext.Comment1.length !== 0) {
            if (logformsuspenseextdata.SuspenseExtData.length !== 1) {
              temp = `${temp} \n ${ext.ShortName}`;
              temp = `${temp} \n ${ext.Comment1}`;
              temp = `${temp} \n -------`;
            } else temp = `${ext.Comment1}`;
          }
        });
        setextraSuspenseSummary(temp);
        if (logformsuspenseextdata?.CanEditSuspense) {
          setCanEditSuspense(true);
        }
      }
    }
  }, [logformsuspenseextdata]);
  return (
    <>
      <>
        <Row>
          <Column>
            <Flex alignItems="center" justifyContent="flex-start">
              <div style={{ maxWidth: '350px' }}>
                <FileInput
                  role="file-input"
                  name={fieldNames.FILE}
                  type="file"
                  maxFileSize={MAX_FILE_SIZE}
                  disabled={logFormType === 'Suspense'}
                  id="file-input"
                  label={false}
                  phrases={{
                    closeAriaLabel: formatMessage({ id: 'logform.attachdoc' }),
                    // this one to be changed in the multiple attachments US
                    multipleFiles: formatMessage({ id: 'logform.attachdoc' }),
                    oneFile: formatMessage({ id: 'logform.attachdoc' }),
                  }}
                  onChange={handleFileChange}
                  invalid={invalidForm}
                  errorMessage={formatMessage({ id: 'logformview.documenttoolarge' })}
                />
              </div>
              <Spacer pl="small" pt="medium">
                <FormCheckBox name={fieldNames.SHARETOINSURLINK}>
                  {formatMessage({ id: 'logform.sharetoinsurlink' })}
                </FormCheckBox>
              </Spacer>
            </Flex>
          </Column>
        </Row>
        <Row>
          <Column xs={12} sm={12} md={10}>
            <Typography variant="h5" data-testid="summarylabeltest">
              {formatMessage({ id: 'logform.summary' })}
            </Typography>
          </Column>
          {!bCreating && (
            <Column xs={12} sm={12} md={2}>
              <div
                style={{
                  textAlign: 'right',
                  float: 'right',
                }}
              >
                <Link onClick={handleSummaryEdit} disabled={!bCanEditSuspense}>
                  {formatMessage({ id: 'logformview.EditSummary' })}
                </Link>
              </div>
            </Column>
          )}
        </Row>
        {bSummaryEdit && (
          <Row>
            <Column xs={12} sm={12} md={12}>
              <TextArea
                name={fieldNames.EXTRASUMMARY}
                label="extrasummary"
                value={suspenseSummary}
                hideLabel={true}
                placeholder={formatMessage({ id: 'logform.typesomething' })}
                autoResize={true}
                maxLength={8000}
                onChange={handleExtraSummaryChange}
                data-testid="detailsbox"
              />
            </Column>
          </Row>
        )}
        <Row>
          <Column xs={12} sm={12} md={12}>
            <TextArea
              name={fieldNames.SUMMARY}
              label={formatMessage({ id: 'logform.summary' })}
              value={extraSuspenseSummary}
              hideLabel={true}
              disabled={!bCreating}
              autoResize={true}
              maxLength={8000}
              onChange={handleSummaryChange}
              data-testid="summarybox"
            />
          </Column>
        </Row>
      </>
    </>
  );
};
export default LogFormSummary;
