import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { LogFormDropdowns } from '../../../models/LogFormDropdowns';
import LogFormDropdownsServices from '../../../services/dropdowns/LogFormDropdownsServices';

export const useLogFormDropdowns = (
  options?: UseQueryOptions<LogFormDropdowns, unknown, LogFormDropdowns, QueryKey>
): UseQueryResult<LogFormDropdowns, any> => {
  const logFormData = useQuery<LogFormDropdowns>(
    ['logformdropdowns'],
    LogFormDropdownsServices.getDropdowns,
    options
  );

  return logFormData;
};
