import { LayoutShell } from 'alloy-foundation';
import { useBottomActionBarState } from '../application-layout/BottomActionBarProvider';
import TopNav from '../../topnav/TopNav';

export const withLayoutShell = <Props extends object>(
  WrappedComponent: React.ComponentType<Props>
) => {
  return (props: Props) => {
    const { bottomActionBar } = useBottomActionBarState();

    return (
      <LayoutShell bottomBar={bottomActionBar} siteHeader={<TopNav />}>
        <WrappedComponent {...props} />
      </LayoutShell>
    );
  };
};
