import {
  MutationOptions,
  QueryKey,
  useMutation,
  useQueries,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { SelectedAssigneesModel, WorkItemRestModel } from '../../../models/WorkItemRestModel';
import ManagerService from '../../../services/manager/ManagerService';

export interface reassignProps {
  selectedWorkItems: WorkItemRestModel[];
  selectedAssignees: SelectedAssigneesModel[];
}

export const useManagerDashboard = (
  options?: UseQueryOptions<WorkItemRestModel[], unknown, WorkItemRestModel[], QueryKey>
) => {
  const query = useQuery<WorkItemRestModel[]>(
    ['managerWorkItems'],
    () => ManagerService.getWorkItemsForSupervisor(),
    options
  );

  return query;
};

export const useAssigneesData = (customerIds: string[]) => {
  const queryArr = [];
  customerIds.forEach((id) =>
    queryArr.push({
      queryKey: ['reassign-inflight', id],
      queryFn: () => ManagerService.getReassignEmployees(id),
    })
  );
  const results = useQueries({
    queries: queryArr,
  });
  return results;
};

export const useReassign = (
  options: MutationOptions<WorkItemRestModel[], unknown, reassignProps, any>
) => {
  const reassignMutation = useMutation<WorkItemRestModel[], unknown, reassignProps, any>(
    (data: reassignProps) =>
      ManagerService.reassignWorkItem(data.selectedWorkItems, data.selectedAssignees),
    options
  );
  return reassignMutation;
};
