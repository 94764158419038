import { Styles } from 'react-with-styles';
import { VERSION_WIDTH, ROW_HEIGHT } from './ExpandedVersionInfo';
const stylesFn = ({ vertaforeAlloy: { color, border } }): Styles => ({
  nameContainer: {
    maxWidth: VERSION_WIDTH,
    ':hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  container: {
    maxHeight: ROW_HEIGHT * 5,
    overflowY: 'auto',

    overflowX: 'hidden',

    scrollbarWidth: '0.625rem',

    scrollbarColor: `${color.scrollbar} transparent`,

    scrollBehavior: 'smooth',

    '::-webkit-scrollbar': {
      width: '0.875rem',

      scrollBehavior: 'smooth',
    },

    '::-webkit-scrollbar-track': {
      background: 'transparent',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',

      backgroundColor: color.scrollbar,

      borderRadius: '0.625rem',

      border: border.extraThickTransparent,
    },
  },
});

export default stylesFn;
