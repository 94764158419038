import {
  MutationKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import { IncompleteWorkItemReduced, newAssignee } from '../../../models/WorkQueue';
import WorkQueueService from '../../../services/workqueue/WorkQueueService';
import { WORK_IN_PROGRESS_KEY } from '../../customers/summary/workInProgress/useWorkInProgressData';
import { WorkInProgressList } from '../../../models/WorkInProgress';
import { WorkItemRestModel } from '../../../models/WorkItemRestModel';
import { ChecklistItemCompletionStatus, Process } from '../../../models/Process';

const CLIENT_KEY = 'Client';
const TASK_KEY = 'Task';
const TITLE_ATTR = 'Title';
const INCOMPLETE_WORK_ITEMS_KEY = 'incomplete_work_items';
const PROCESS = 'process';

export const useWorkQueue = () => {
  const workQueue = useQuery([INCOMPLETE_WORK_ITEMS_KEY], () => WorkQueueService.list(), {
    select: (data) => {
      return data.map((p, i) => {
        const customer = p.Payload?.BusinessObjects?.find((x) => x.Key === CLIENT_KEY)?.Value;
        const customerName = customer?.Description;
        const customerId = customer?.Id;
        const task = p.Payload?.BusinessObjects?.find((x) => x.Key === TASK_KEY);
        const taskTitle = task?.Value?.Attributes?.find((x) => x.Name === TITLE_ATTR)?.Value;
        const processTitle = `${p.ShortTitle} - ${p.Title}`;
        const formattedDueDate = DateTime.fromISO(p.DueDate, { zone: 'utc' })
          .toLocal()
          .toFormat('MM/dd/yyyy');
        return {
          CustomerName: customerName,
          CustomerId: customerId,
          Id: p.Id,
          Type: p.Type,
          Priority: p.Priority,
          ProcessTitle: processTitle,
          TaskTitle: taskTitle,
          DueDate: p.DueDate,
          FormattedDueDate: formattedDueDate,
        } as IncompleteWorkItemReduced;
      });
    },
  });
  return workQueue;
};

export interface UpdateWorkItemPriorityProps {
  id: string;
  priority: number;
  customerId: string;
}

export const useUpdateWorkItemPriority = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const customerMutation = useMutation<
    UpdateWorkItemPriorityProps,
    unknown,
    UpdateWorkItemPriorityProps,
    any
  >(
    (data: UpdateWorkItemPriorityProps) => WorkQueueService.updatePriority(data.id, data.priority),
    {
      onSuccess: (data, variables) => {
        // update work item priority in panel query data
        queryClient.setQueryData<IncompleteWorkItemReduced[] | undefined>(
          [INCOMPLETE_WORK_ITEMS_KEY],
          (items) => {
            if (items)
              items = items.map((p) =>
                p.Id === variables.id ? { ...p, Priority: variables.priority } : p
              );

            return items;
          }
        );

        // update Work In Progress grid
        queryClient.setQueryData<WorkInProgressList | undefined>(
          [WORK_IN_PROGRESS_KEY, variables.customerId],
          (list) => {
            if (list)
              list.Data = list.Data?.map((p) =>
                p.Id === variables.id
                  ? {
                      ...p,
                      PriorityId: variables.priority,
                      PriorityDisplayName: formatMessage({
                        id: `drawer.workQueue.priority.${
                          variables.priority === 1 ? 'critical' : 'normal'
                        }`,
                      }),
                    }
                  : p
              );

            return list;
          }
        );
      },
    }
  );

  return customerMutation;
};
export interface availableAssignee {
  data?: [{ value: newAssignee; label: string }];
  isLoading?: boolean;
}
export const useAvailableAssignee = (id: string, options?: UseQueryOptions<unknown>) => {
  const { data, isLoading } = useQuery(
    ['availableAssignee'],
    () =>
      WorkQueueService.getAvailableAssignee(id).then((res) =>
        res.map((i) => {
          return {
            value: i,
            label: i.DisplayName,
          };
        })
      ),
    options
  );

  return { data, isLoading } as availableAssignee;
};
export interface ReassignItemProps {
  Id: string;
  newAssignee: newAssignee;
  CustomerId: string;
}
export const useReassignItem = () => {
  const queryClient = useQueryClient();
  const reassignMutation = useMutation<ReassignItemProps, unknown, ReassignItemProps, any>(
    (data: ReassignItemProps) =>
      WorkQueueService.reassignItem(data.Id, {
        Type: data.newAssignee.Type,
        Id: data.newAssignee.Id,
        DisplayName: data.newAssignee.DisplayName,
      }),
    {
      onSuccess: (data, variables, context) => {
        // Invalidate the queries to update on success
        queryClient.invalidateQueries(['incomplete_work_items']);
        queryClient.invalidateQueries(['GetWorkQueueIncompleteCount']);
        queryClient.setQueryData<WorkInProgressList | undefined>(
          [WORK_IN_PROGRESS_KEY, variables.CustomerId],
          (list) => {
            return updateCacheHelper(
              list,
              variables,
              variables.newAssignee.DisplayName,
              +variables.newAssignee.Id
            );
          }
        );
      },
      onError: (e) => {
        // handle error
        queryClient.invalidateQueries(['incomplete_work_items']);
        queryClient.invalidateQueries(['GetWorkQueueIncompleteCount']);
      },
    }
  );

  return reassignMutation;
};
export interface DeleteItemProps {
  Id: string;
  CustomerId: string;
}
export const useDeleteItem = () => {
  const queryClient = useQueryClient();
  const deleteMutation = useMutation<DeleteItemProps, unknown, DeleteItemProps, any>(
    (data: DeleteItemProps) => WorkQueueService.deleteItem(data.Id),
    {
      onSuccess: (data, variables, context) => {
        // Invalidate the queries to update on success
        queryClient.invalidateQueries(['incomplete_work_items']);
        queryClient.invalidateQueries(['GetWorkQueueIncompleteCount']);
        // Small note : if setQueryData get an undefined value, it doesn't create an entry in its store.
        queryClient.setQueryData(['managerWorkItems'], (oldData: WorkItemRestModel[]) =>
          oldData ? oldData.filter((w) => w.Id !== variables.Id) : undefined
        );
        queryClient.setQueryData<WorkInProgressList | undefined>(
          [WORK_IN_PROGRESS_KEY, variables.CustomerId],
          (list) => {
            return updateCacheHelper(list, variables);
          }
        );
      },
      onError: (e) => {
        // handle error
        queryClient.invalidateQueries(['incomplete_work_items']);
        queryClient.invalidateQueries(['GetWorkQueueIncompleteCount']);

        // Invalidating the query instead of correcting as to force are reload and getting the correct work items from the back-end.
        // The backend might have deleted the workitem and failed after.
        queryClient.invalidateQueries(['managerWorkItems']);
      },
    }
  );

  return deleteMutation;
};

export const useProcess = (id: string) => {
  const process = useQuery([PROCESS, id], () => WorkQueueService.getProcess(id), {
    select: (data) => {
      data.FormattedCreatedDate = DateTime.fromISO(data.CreatedDate, { zone: 'utc' })
        .toLocal()
        .toFormat('MM/dd/yyyy');
      data.FormattedDueDate = DateTime.fromISO(data.DueDate, { zone: 'utc' })
        .toLocal()
        .toFormat('MM/dd/yyyy');
      data.Tasks?.forEach((p) => {
        if (p.DueDate)
          p.FormattedDueDate = DateTime.fromISO(p.DueDate, { zone: 'utc' })
            .toLocal()
            .toFormat('MM/dd/yyyy');
        if (p.CompletedDate)
          p.FormattedCompletedDate = DateTime.fromISO(p.CompletedDate, { zone: 'utc' })
            .toLocal()
            .toFormat('MM/dd/yyyy');
      });

      return data;
    },
  });

  return process;
};

export interface UpdateTaskStatusProps {
  Id: string;
  processId: string;
  status: ChecklistItemCompletionStatus;
}

export const useUpdateTaskStatus = (mutationKey: MutationKey, onError: () => void) => {
  const queryClient = useQueryClient();
  const taskMutation = useMutation<Process, unknown, UpdateTaskStatusProps, any>(
    mutationKey,
    (data: UpdateTaskStatusProps) => WorkQueueService.updateTaskStatus(data.Id, data.status),
    {
      onSuccess: (data, variables) => {
        // update entire work item step drawer with returned data
        queryClient.setQueryData<Process>([PROCESS, variables.processId], (p) => data);
        // update work in progress list
        queryClient.setQueryData<WorkInProgressList>(
          [
            WORK_IN_PROGRESS_KEY,
            data.BusinessObjects.find((buisnessObject) => buisnessObject.Index === 'Client').Id,
          ],
          (list) => {
            return updateCacheHelper(list, variables, null, null, data);
          }
        );
      },
      onError,
    }
  );

  return taskMutation;
};

export const updateCacheHelper = (
  list: WorkInProgressList,
  variables: ReassignItemProps | DeleteItemProps | UpdateTaskStatusProps,
  newDisplayName?: string,
  newId?: number,
  data?: Process
) => {
  if (list) {
    // If there are 3 variables the user is reassigning a work item assignee or just completed a task, so we check the action and update the relative cache
    // If there are 2 variables the user is deleting a work item, so we remove the item form the cache
    switch (Object.keys(variables).length) {
      case 3: {
        if (Object.prototype.hasOwnProperty.call(variables, 'processId')) {
          const process = variables as UpdateTaskStatusProps;
          list.Data = list.Data?.map((p) =>
            p.Id === process.processId
              ? {
                  ...p,
                  Description: data?.CurrentTask?.Definition.Title ?? p.Description,
                  DueDate: data?.CurrentTask?.DueDate.toString() ?? p.DueDate,
                }
              : p
          );
          // if every task is complete filter the completed item out of the list
          if (data?.Tasks.every((x) => x.Status === 1)) {
            list.Data = list.Data.filter((item) => item?.Id !== data?.Id);
          }
        } else {
          list.Data = list.Data?.map((p) =>
            p.Id === variables.Id
              ? {
                  ...p,
                  AssignedToName: newDisplayName ?? p.AssignedToName,
                  AssignedToId: newId ?? p.AssignedToId,
                }
              : p
          );
        }
        return list;
      }
      case 2: {
        list.Data = list.Data?.filter((p) => p.Id !== variables.Id);
        return list;
      }

      default:
        return list;
    }
  }
};
