import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import productInfoService from '../../services/productInfo/productInfoService';
import { LocationInfo, ProductInfo } from '../../models/ProductInfo';
import LoginService from '../../services/loginService/LoginService';
import { HTTP_STATUS } from '../layout/errorPages/LoginError';

interface IProductInfoContext {
  productInfo?: ProductInfo;
  loginError?: ErrorDetails;
  handleError?: (p) => void;
  selectAgency?: (selectedAgencyId: string, selectedGroupId: number) => void;
  setProductInfo?: () => void;
  getIsCustomerAdmin?: () => boolean;
  getIsSmartflowAdmin?: () => boolean;
  getAgencyNumber?: () => string;
  getVimUserId?: () => number;
  getEmpCode?: () => string;
  getUserFullName?: () => string;
  getLocationInfo?: () => LocationInfo;
  setAvailable?: React.Dispatch<React.SetStateAction<boolean>>;
  prodInfoAvailable: boolean;
  getMaxDocSize?: () => number;
}
interface ErrorDetails {
  errorCode?: number;
  errorDetail?: string;
}
const ProductInfoContext = React.createContext<IProductInfoContext>({
  prodInfoAvailable: false,
});
export const ProductInfoProvider = ({ children }) => {
  const [prodInfoAvailable, setAvailable] = useState(false);
  const [productInfo, setProductInfoData] = useState<ProductInfo>();
  const history = useHistory();
  const [loginError, setLoginError] = useState<ErrorDetails>();

  const handleError = useCallback(
    (err) => {
      const errorCode = err.response?.status ?? null;
      setLoginError({
        errorCode,
        errorDetail:
          errorCode === HTTP_STATUS.FORBIDDEN || errorCode === HTTP_STATUS.UNAUTHORIZED
            ? err.response.data?.Message ?? null
            : null,
      });
      history.push('/Login/Error');
    },
    [history]
  );
  const setProductInfo = useCallback(async () => {
    await productInfoService
      .retrieveProductInfo()
      .then((data) => {
        setProductInfoData(data);
        setAvailable(true);
      })
      .catch((err) => {
        handleError(err);
      });
  }, [handleError]);
  const selectAgency = useCallback(
    (selectedAgencyId: string, selectedGroupId: number) => {
      LoginService.SelectAgency(selectedAgencyId, selectedGroupId)
        .then(() => {
          setProductInfo();
          history.push('/dashboard');
        })
        .catch((err) => {
          handleError(err);
        });
    },
    [handleError, history, setProductInfo]
  );
  const getIsCustomerAdmin = (): boolean => {
    return productInfo?.IsCustomerAdmin;
  };
  const getIsSmartflowAdmin = (): boolean => {
    return productInfo?.ProductInfos.find((p) => p.Identifier === 'smartflow')?.Data?.IsManager;
  };
  const getAgencyNumber = (): string => {
    return productInfo?.AgencyNumber;
  };
  const getVimUserId = (): number => {
    return productInfo?.VimUserId;
  };

  const getEmpCode = (): string => {
    return productInfo?.ProductInfos[0]?.Data?.ECode;
  };
  const getUserFullName = (): string => {
    return productInfo?.UserFullName;
  };
  const getLocationInfo = (): LocationInfo => {
    return productInfo?.LocationInfo;
  };

  const getMaxDocSize = (): number => {
    return productInfo?.ProductInfos[0]?.Data?.MaxDocSize ?? 1;
  };

  return (
    <ProductInfoContext.Provider
      value={{
        getIsCustomerAdmin,
        getIsSmartflowAdmin,
        getAgencyNumber,
        getVimUserId,
        getEmpCode,
        getUserFullName,
        getLocationInfo,
        loginError,
        handleError,
        prodInfoAvailable,
        setProductInfo,
        setAvailable,
        productInfo,
        selectAgency,
        getMaxDocSize,
      }}
    >
      {children}
    </ProductInfoContext.Provider>
  );
};

export const useProductInfoProvider = () => {
  const context = useContext(ProductInfoContext);
  if (context === undefined) {
    throw new Error(
      'useProductInfoProvider cannot be called from outside of a ProductInfoProvider'
    );
  }
  return context;
};
