import {
  Button,
  ButtonGroup,
  LoadingIndicator,
  Modal,
  RadioButton,
  RadioButtonController,
} from 'alloy-foundation';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { GetQuickNavActionName, QuickNavAction } from '../../../models/enums/QuickNavActions';
import { PolicyReduced } from '../../../models/PolicySummary';
import { PssCustomer } from '../../../models/PssCustomer';
import { PublishedProcessRestModel } from '../../../models/smartflow/PublishedProcessRestModel';
import { useCommunicationService } from '../../communicationService/CommunicationServiceProvider';
import { useDrawer } from '../../drawer/DrawerProvider';
import WorkQueueDrawer from '../../drawer/workQueue';
import {
  mutationProps,
  useMutationPublishing,
  usePublishedProcesses,
} from './useInstancesSelection';

export interface InstanceSelectionProps {
  onClose(): void;
  quickNavAction: QuickNavAction;
  policy?: PolicyReduced;
  customer?: PssCustomer;
  customerName?: string;
}

enum InstanceSelectionView {
  QuickNavTypeSelection,
  InstanceSelection,
}

const isPersonalQuickNav = (quickNav: PublishedProcessRestModel) => {
  return (
    quickNav.Name !== 'CNMRenewal' &&
    quickNav.Name !== 'CLBPRenewal' &&
    quickNav.Name !== 'CMRenewal'
  );
};

const InstanceSelection = (props: InstanceSelectionProps) => {
  const { formatMessage } = useIntl();
  const { showForm } = useCommunicationService();
  const instanceSelectionData = usePublishedProcesses({
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
  const queryClient = useQueryClient();
  const { showDrawer } = useDrawer();
  const mutation = useMutationPublishing({
    onSuccess: (data: any, variables, context) => {
      showDrawer(<WorkQueueDrawer id={data.Id} />);

      let custId = props.customer?.CustomerId;
      if (!custId && props.policy) {
        custId = props.policy.CustId;
      }
      queryClient.invalidateQueries(['workInProgress', custId]);
    },
  });
  const [value, setValue] = useState<string>();
  const [instance, setInstance] = useState<PublishedProcessRestModel>();
  const [mode, setMode] = useState<InstanceSelectionView>(
    InstanceSelectionView.QuickNavTypeSelection
  );

  useEffect(() => {
    setMode(InstanceSelectionView.QuickNavTypeSelection);
    if (props.quickNavAction !== QuickNavAction.PolicyCancellation) {
      setValue(undefined);
    } else {
      setValue('Sig');
    }
    setInstance(undefined);
  }, [props, setMode, setValue, setInstance]);

  const getSelectedItem = (): PublishedProcessRestModel => {
    return instanceSelectionData.data
      .find((p) => p.Name === GetQuickNavActionName(props.quickNavAction))
      ?.Variations.find((p) => p.Id.toString() === value);
  };

  const Step1QuickNavTypeSelection = () => {
    const mainType = instanceSelectionData.data?.find(
      (p) => p.Name === GetQuickNavActionName(props.quickNavAction)
    );
    let templates = mainType?.Variations.filter((p) => p.Variations.length > 0) ?? [];
    if (props.policy?.PolicyClass === 'P') {
      templates = templates.filter((p) => isPersonalQuickNav(p));
    }
    // Remove the template without any valid children. The old code was basing on what is a valid children from the presence of a title or not.
    // And it is a four layer structure with the 2 bottom layers merges togother when shown regardless if the third layer object is valid or not.
    templates = templates.filter((f) =>
      f.Variations?.some(
        (t) => (t.Title ?? '') !== '' || t.Variations?.some((v) => (v.Title ?? '') !== '')
      )
    );

    return (
      <RadioButtonController
        value={value}
        name="QuickNav"
        hideLabel={true}
        label=""
        onChange={(values) => {
          setValue(values);
        }}
      >
        {templates
          .sort((a, b) => a.Title.localeCompare(b.Title))
          .map((p) => (
            <RadioButton value={p.Id.toString()} data-testid={p.Title} key={p.Id}>
              {p.Title}
            </RadioButton>
          ))}
        <RadioButton value="Sig" data-testid="SigOptions">
          {getSigButtonText()}
        </RadioButton>
      </RadioButtonController>
    );
  };

  const Step2InstanceSelectionRadioButtons = () => {
    const selectedItem = getSelectedItem();

    let instancesList = [];
    if (selectedItem && selectedItem.Variations) {
      selectedItem.Variations.forEach(function (item) {
        instancesList.push(item);
        const subVariations = item.Variations;
        if (subVariations) {
          subVariations.forEach(function (subVariation) {
            instancesList.push(subVariation);
          });
        }
      });
    }

    // Invalid object need to be removed.
    instancesList = instancesList.filter((v) => (v.Title ?? '') !== '');

    instancesList = instancesList.sort((a, b) => a.Title.localeCompare(b.Title));

    return (
      <RadioButtonController
        renderErrorMessage={false}
        value={instance?.Id.toString()}
        name="InstanceVariation"
        label=""
        hideLabel={true}
        onChange={(values) => {
          setInstance(instancesList.find((p) => p.Id.toString() === values));
        }}
      >
        {instancesList.map((p) => (
          <RadioButton value={p.Id.toString()} key={p.Id}>
            {p.Title}
          </RadioButton>
        ))}
      </RadioButtonController>
    );
  };

  const handleStep1Next = () => {
    if (value !== 'Sig') {
      setMode(InstanceSelectionView.InstanceSelection);
    } else {
      switch (props.quickNavAction) {
        case QuickNavAction.NewPolicy:
          showForm('PolicyAction', { Mode: 'NEW', CustId: props.customer.CustomerId });
          break;
        case QuickNavAction.PolicyRenewal:
          showForm('PolicyAction', {
            Mode: 'RENEW',
            PolId: props.policy.Id,
            EndEffDate: props.policy.EndEffDate,
          });
          break;
        case QuickNavAction.PolicyCancellation:
          showForm('PolicyAction', {
            Mode: 'CANCEL',
            PolId: props.policy.Id,
            EndEffDate: props.policy.EndEffDate,
          });
          break;
        case QuickNavAction.PolicyEndorsement:
          showForm('PolicyAction', {
            Mode: 'ENDORSE',
            PolId: props.policy.Id,
            EndEffDate: props.policy.EndEffDate,
            CustId: props.customer.CustomerId,
          });
          break;
      }
      props.onClose();
    }
  };

  const getSigButtonText = () => {
    switch (props.quickNavAction) {
      case QuickNavAction.NewPolicy:
        return formatMessage({ id: 'quickNav.instanceSelection.newSig' });
      case QuickNavAction.PolicyCancellation:
        return formatMessage({ id: 'quickNav.instanceSelection.cancelSig' });
      case QuickNavAction.PolicyRenewal:
        return formatMessage({ id: 'quickNav.instanceSelection.renewSig' });
      case QuickNavAction.PolicyEndorsement:
        return formatMessage({ id: 'quickNav.instanceSelection.endorseSig' });
    }
  };

  const getSubTitleText = () => {
    if (mode === InstanceSelectionView.QuickNavTypeSelection) {
      return formatMessage({ id: 'quickNav.instanceSelection.subTitle1' });
    }

    const quickNav = getSelectedItem()?.Title;

    return formatMessage({ id: 'quickNav.instanceSelection.subTitle2' }, { quickNav });
  };

  const handleStep2Finish = () => {
    const currentTemplate = instanceSelectionData.data
      .find((p) => p.Name === GetQuickNavActionName(props.quickNavAction))
      ?.Variations.find((p) => p.Id.toString() === value);
    const variables: mutationProps = {
      customer: props.customer,
      customerNameString: props.customerName,
      quickNav: currentTemplate,
      quickNavAction: props.quickNavAction,
      selectedVariation: instance,
      policy: props.policy,
    };
    mutation.mutate(variables);
    props.onClose();
  };

  const InstanceSelectionFooter = () => {
    return (
      <>
        {mode === InstanceSelectionView.QuickNavTypeSelection ? (
          <ButtonGroup endAlign>
            <Button variant="tertiary" onClick={props.onClose}>
              {formatMessage({ id: 'common.button.cancel' })}
            </Button>
            <Button onClick={handleStep1Next} variant="primary" disabled={(value ?? '') === ''}>
              {value !== 'Sig' ? formatMessage({ id: 'common.button.next' }) : getSigButtonText()}
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup endAlign>
            <Button
              variant="tertiary"
              onClick={() => {
                setMode(InstanceSelectionView.QuickNavTypeSelection);
                setInstance(undefined);
              }}
            >
              {formatMessage({ id: 'common.button.back' })}
            </Button>
            <Button onClick={handleStep2Finish} variant="primary" disabled={!instance}>
              {formatMessage({ id: 'quickNav.instanceSelection.begin' })}
            </Button>
          </ButtonGroup>
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      <Modal
        onClose={props.onClose}
        footer={<InstanceSelectionFooter />}
        title={formatMessage({ id: 'quickNav.instanceSelection.title' })}
        subtitle={getSubTitleText()}
        data-testid="instanceSelection"
        multiStep={true}
        scrollable={true}
      >
        {
          // Show LoadingIndicator when fetching as it will only show when the component is render for the first time.
          // Also, when the fetch is finish, weird thing happens at the UI level if showing list.
          instanceSelectionData.isFetching ? (
            <LoadingIndicator />
          ) : mode === InstanceSelectionView.QuickNavTypeSelection ? (
            <Step1QuickNavTypeSelection />
          ) : (
            <Step2InstanceSelectionRadioButtons />
          )
        }
      </Modal>
    </React.Fragment>
  );
};

export default InstanceSelection;
