import { Button, ButtonGroup, Link, TableCell, TableRow, Typography } from 'alloy-foundation';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { Version } from '../../../models/QuickNavInstance';
import { useFormatting } from '../../common/hooks/useFormatting';
import { LASTUPDATE_WIDTH, VERSION_WIDTH } from './ExpandedVersionInfo';
import QuickNavModal, { ModalProps } from './QuickNavModal';
import stylesFn from './styles';
import { usePublishVersion } from './useInstance';
interface VersionProps {
  version: Version;
  instanceId: number;
  templateId: string;
  rowIndex: number;
}
const VersionCard = (props: VersionProps) => {
  const { css, styles } = useStyles({ stylesFn });
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { formatModifiedDate } = useFormatting();
  const queryClient = useQueryClient();
  const currentVersion = props?.version;
  const [modalData, setModalData] = useState<ModalProps>(null);
  const restoreMutation = usePublishVersion({
    onSuccess() {
      queryClient.invalidateQueries(['quicknav-instance', props?.templateId]);
    },
  });
  const handleRestore = () => {
    setModalData({
      Name: currentVersion.Title ?? '',
      Title: formatMessage({ id: 'quickNav.configuration.instances.publish.title' }),
      SubTitle: formatMessage({ id: 'quickNav.configuration.instances.publish.subtitle.pt1' }),
      SubTitle2: formatMessage({ id: 'quickNav.configuration.instances.publish.subtitle.pt2' }),
      confirmText: formatMessage({ id: 'quickNav.configuration.instances.publish.button' }),
      onCancel: () => {
        setModalData(null);
      },
      onConfirm: () => {
        setModalData(null);
        restoreMutation.mutate({ version: currentVersion, isNewInstance: false });
      },
    });
  };
  return (
    <TableRow display="flex" alignItems="baseline" border={false}>
      <TableCell
        width={VERSION_WIDTH}
        padding="default"
        data-testid={`row-${props?.rowIndex}-VersionName`}
      >
        <div {...css(styles.nameContainer)}>
          <Typography
            noWrap={true}
            variant="h5"
            onClick={() =>
              history.push(
                `/quicknav/configure/${props?.instanceId ?? ''}/instance/${currentVersion?.Id}`
              )
            }
          >
            {props?.version?.Title}
          </Typography>
        </div>
        <Typography variant="bodySm" noWrap={true}>
          {props?.version?.IsCurrentlyPublished
            ? formatMessage({ id: 'quickNav.configuration.instances.published' })
            : props?.version?.IsDraft
            ? formatMessage({ id: 'quickNav.configuration.instances.draft' })
            : formatMessage({ id: 'quickNav.configuration.instances.unpublished' })}
        </Typography>
      </TableCell>
      <TableCell data-testid={`row-${props?.rowIndex}-LastUpdate`}>
        <ButtonGroup>
          <div style={{ width: LASTUPDATE_WIDTH }}>
            <Typography variant="bodySm">
              {formatModifiedDate(currentVersion?.LastModifiedDate)}
            </Typography>
            <Typography variant="bodySm" noWrap>
              {currentVersion?.LastModifiedByName ?? ''}
            </Typography>
          </div>
          <Button
            size="small"
            icon="edit"
            variant="link"
            onClick={() =>
              history.push(
                `/quicknav/configure/${props?.instanceId ?? ''}/instance/${currentVersion?.Id}`
              )
            }
          ></Button>
          {!currentVersion?.IsCurrentlyPublished && !currentVersion.IsDraft && (
            <Link small onClick={handleRestore}>
              {formatMessage({ id: 'quickNav.configuration.instances.expanded.restore' })}
            </Link>
          )}
        </ButtonGroup>
      </TableCell>
      {modalData && <QuickNavModal {...modalData}></QuickNavModal>}
    </TableRow>
  );
};

export default VersionCard;
