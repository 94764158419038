import {
  Column,
  Container,
  DropdownFilterSelect,
  DropdownMultiSelectSearch,
  LoadingIndicator,
  Row,
  Spacer,
} from 'alloy-foundation';
import { useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import type { OptionType } from 'alloy-foundation';
import { useIntl } from 'react-intl';
import { CustomerDropdowns } from '../../../models/CustomerDropdowns';
import { SearchResult } from '../../../models/SearchResult';
import { mapKeyValueToOptions } from '../../../models/KeyValuePair';
import { AdvanceFilter } from '../../data-table/useDataTable';
import Department from '../../../models/Department';

const GlobalSearchAdvanceFilter = (
  dropdownsQuery: UseQueryResult<CustomerDropdowns, any>
): AdvanceFilter<SearchResult> => {
  const [accountTypes, updateAccountTypes] = useState<OptionType<unknown>[]>([]);
  const [customerType, updateCustomerType] = useState<OptionType<unknown>[]>([]);
  const [customerStatus, updateCustomerStatus] = useState<OptionType<unknown>[]>([]);
  const [currentDivision, updateCurrentDivision] = useState<OptionType<unknown>>(null);
  const [department, updateDepartment] = useState<OptionType<unknown>>(null);
  const [departments, setDepartments] = useState<Department[]>(null);
  const { formatMessage, locale } = useIntl();
  const customerTypeOptions = mapKeyValueToOptions(dropdownsQuery?.data?.ClientTypes ?? []);
  const accountTypeOptions = [
    {
      value: 'C',
      label: formatMessage({
        id: 'customer.details.businessInformation.typeOfBusiness.Commercial',
      }),
    },
    {
      value: 'P',
      label: formatMessage({ id: 'customer.details.businessInformation.typeOfBusiness.Personal' }),
    },
  ];

  const customerStatusOptions = [
    {
      value: true,
      label: formatMessage({ id: 'common.active' }),
    },
    { value: false, label: formatMessage({ id: 'common.inactive' }) },
  ];

  const handleTypeChange = (items?: OptionType<unknown>[]) => {
    updateCustomerType(items);
  };

  const handleAccountTypeChange = (items?: OptionType<unknown>[]) => {
    updateAccountTypes(items);
  };

  const handleStatusChange = (items?: OptionType<unknown>[]) => {
    updateCustomerStatus(items);
  };

  const handleDivisionChange = (division) => {
    const newDivision = dropdownsQuery.data.BusinessUnits.Divisions.find(
      (x) => x.DivisionCode === division?.value
    );

    if (newDivision) {
      setDepartments(newDivision.Departments);
    } else {
      setDepartments([]);
    }

    updateCurrentDivision(division);
    updateDepartment(null);
  };

  const handleDeptChange = (dept) => {
    updateDepartment(dept);
  };

  const clearFilter = () => {
    updateAccountTypes([]);
    updateCustomerType([]);
    updateCustomerStatus([]);
    updateCurrentDivision(null);
    updateDepartment(null);
    setDepartments(null);
  };

  const display = (
    <div data-testid="GlobalSearchAdvanceFilter">
      {dropdownsQuery.isLoading ? (
        <Spacer mb="small">
          <LoadingIndicator />
        </Spacer>
      ) : (
        <Container noPadding={true}>
          <Row>
            <Column md={6}>
              <DropdownMultiSelectSearch
                data-testid="searchCustomerType"
                options={customerTypeOptions}
                onChange={handleTypeChange}
                selectedItems={customerType}
                label={formatMessage({ id: 'customer.details.base.type.label' })}
                placeholder={formatMessage({ id: 'customer.search.selectCustomerType' })}
                required={false}
                visibleChips={locale === 'fr-CA' ? 3 : 4}
              />
            </Column>
            <Column md={3}>
              <DropdownMultiSelectSearch
                data-testid="searchAccountType"
                options={accountTypeOptions}
                selectedItems={accountTypes}
                onChange={handleAccountTypeChange}
                label={formatMessage({ id: 'customer.details.businessInformation.typeOfBusiness' })}
                placeholder={formatMessage({
                  id: 'customer.search.selectAccountType',
                })}
                required={false}
              />
            </Column>
            <Column md={3}>
              <DropdownMultiSelectSearch
                data-testid="searchStatus"
                selectedItems={customerStatus}
                options={customerStatusOptions}
                onChange={handleStatusChange}
                label={formatMessage({ id: 'customer.search.Active' })}
                placeholder={formatMessage({ id: 'customer.search.selectStatus' })}
                required={false}
              />
            </Column>
          </Row>
          <Row>
            <Column md={6}>
              <DropdownFilterSelect
                data-testid="searchDivision"
                options={
                  dropdownsQuery.data?.BusinessUnits.Divisions.map((data) => {
                    return { value: data.DivisionCode, label: data.DivisionName };
                  }) ?? []
                }
                selectedItem={currentDivision}
                onChange={handleDivisionChange}
                placeholder={formatMessage({
                  id: 'customer.details.businessInformation.division.placeholder',
                })}
                noSpacing={true}
                renderErrorMessage={false}
                label={formatMessage({ id: 'customer.details.businessInformation.division' })}
              />
            </Column>
            <Column md={6}>
              <DropdownFilterSelect
                data-testid="searchDept"
                disabled={!departments || departments?.length === 0}
                placeholder={formatMessage({
                  id: 'customer.details.businessInformation.department.placeholder',
                })}
                options={
                  departments?.map((data) => {
                    return { value: data.DepartmentCode, label: data.DepartmentName };
                  }) ?? []
                }
                onChange={handleDeptChange}
                label={formatMessage({ id: 'customer.details.businessInformation.department' })}
                selectedItem={department}
                noSpacing={true}
                renderErrorMessage={false}
              />
            </Column>
          </Row>
        </Container>
      )}
    </div>
  );

  const fitlerFunction = (data: SearchResult[]) => {
    let result = data;

    if (
      customerType &&
      customerType.length > 0 &&
      customerType.length < customerTypeOptions.length
    ) {
      result = result.filter((customer) =>
        customerType.some((a) => a.value === customer.ClientStatus)
      );
    }

    if (
      accountTypes &&
      accountTypes.length > 0 &&
      accountTypes.length < accountTypeOptions.length
    ) {
      result = result.filter((customer) =>
        accountTypes.some((a) => a.value === customer.ClientType)
      );
    }

    // Only 2 possible status and if none are checked, it is the same both are checked.
    // Thus we only have to handle the scenario where only one is checked.
    if (customerStatus && customerStatus.length === 1) {
      result = result.filter((customer) => customer.Active === customerStatus[0].value);
    }

    if (currentDivision && currentDivision.label) {
      result = result.filter((customer) => customer.Division === currentDivision.label);

      if (department && department.label) {
        result = result.filter((customer) => customer.Department === department.label);
      }
    }

    return result;
  };

  return {
    display,
    filterData: fitlerFunction,
    filteredParameters: `${
      (customerStatus ? customerStatus.map((c) => c.label).join('-,') : '') +
      (customerType ? customerType.map((c) => c.label).join('-,') : '') +
      (accountTypes ? accountTypes.map((c) => c.label).join('-,') : '') +
      (currentDivision ? currentDivision.label : '')
    }-,${department ? department.label : ''}`,
    clearFilter,
  };
};
export default GlobalSearchAdvanceFilter;
