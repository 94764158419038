import {
  ContentHeader,
  Group,
  Layout,
  LayoutShell,
  ScrollSection,
  ScrollWrapper,
} from 'alloy-foundation';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import MyProfileBrokerageInfo from './brokerage-info/MyProfileBrokerageInfo';
import MyProfileGeneralInfo from './general-info/MyProfileGeneralInfo';
import { MY_PROFILE_SCROLL_SECTIONS } from './MyProfileScrollSections';
import MyProfileSideMenu from './MyProfileSideMenu';
import MyProfileUpdatePassword from './update-password/MyProfileUpdatePassword';
import TopNav from '../topnav/TopNav';

const MyProfile = () => {
  const [activeSection, setActiveSection] = useState<string>(
    MY_PROFILE_SCROLL_SECTIONS.GENERAL_INFO_SECTION_ID
  );
  const { formatMessage } = useIntl();

  return (
    <>
      <LayoutShell
        siteHeader={<TopNav />}
        disableScrollbarGutter
        sideMenu={<MyProfileSideMenu activeSection={activeSection} />}
      >
        <ScrollWrapper onChangeActiveScrollSection={setActiveSection}>
          <Layout>
            <ContentHeader title={formatMessage({ id: 'myProfile.title' })} />
            <Group spacing="medium">
              <ScrollSection
                id={MY_PROFILE_SCROLL_SECTIONS.GENERAL_INFO_SECTION_ID}
                data-testid={MY_PROFILE_SCROLL_SECTIONS.GENERAL_INFO_SECTION_ID}
              >
                <MyProfileGeneralInfo />
              </ScrollSection>
              <ScrollSection
                id={MY_PROFILE_SCROLL_SECTIONS.UPDATE_PASSWORD_SECTION_ID}
                data-testid={MY_PROFILE_SCROLL_SECTIONS.UPDATE_PASSWORD_SECTION_ID}
              >
                <MyProfileUpdatePassword />
              </ScrollSection>
              <ScrollSection
                id={MY_PROFILE_SCROLL_SECTIONS.BROKERAGE_INFO_SECTION_ID}
                data-testid={MY_PROFILE_SCROLL_SECTIONS.BROKERAGE_INFO_SECTION_ID}
              >
                <MyProfileBrokerageInfo />
              </ScrollSection>
            </Group>
            {/* This div allows us to add extra spacing at the bottom to let the SideMenu/ScrollSection 
                render correctly when scrolling to the bottom of the page.
                This is how AgencyOne addresses the issue. */}
            <div style={{ height: '160px' }} />
          </Layout>
        </ScrollWrapper>
      </LayoutShell>
    </>
  );
};

export default MyProfile;
