import { Button, ButtonGroup, Modal, VertaFormik, useAppContext } from 'alloy-foundation';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { Handle } from '../../common/handle';
import LogFormControl from './LogFormControl';
import { LogFormData } from '../../../models/LogFormData';
import { LogFormServices } from '../../../services/logForm/LogFormService';
import logFormValidationSchema from './LogFormValidationSchema';
import { useLogFormProvider } from '../../common/providers/LogFormProvider';
import { useFormatting } from '../../common/hooks/useFormatting';
import { useProductInfoProvider } from '../../productInfo/ProductInfoProvider';
import Configuration from '../../../Configuration';

export type ItemProps = {
  label: string;
  value: string;
};

export const LogFormActivity = 'Activity';
export const LogFormSuspense = 'Suspense';

export const LogFormEmployeeAssigned = 'employee';
export const LogFormGroupAssigned = 'group';
const baseUrl = `${Configuration.appBaseUri}/customers/`;

const LogForm = () => {
  const {
    logFormType,
    logFormVisible,
    hideLogForm,
    logFormCustomer,
    setLogFormCustomer,
    logFormPolicy,
    logFormTransactionDate,
    logFormSuspense,
  } = useLogFormProvider();
  const { addErrorToast, addSuccessToast } = useAppContext();
  const { formatMessage } = useIntl();
  const { formatDate } = useFormatting();
  const { getEmpCode } = useProductInfoProvider();
  const initialValues = () => {
    let assignedtodropdn = '';
    if (logFormType === LogFormSuspense) {
      if (logFormSuspense?.SuspGrpId) {
        assignedtodropdn = logFormSuspense.SuspGrpId;
      } else {
        assignedtodropdn = logFormSuspense?.AssignedToCode;
      }

      if (!assignedtodropdn || assignedtodropdn === '') {
        assignedtodropdn = getEmpCode();
      }
    }

    return {
      status: logFormType === LogFormActivity ? 2 : logFormSuspense?.IsCompleted ? 2 : 1,
      customer: logFormCustomer ? logFormCustomer.value : '',
      policy: logFormPolicy ? logFormPolicy : '',
      transactiondate: logFormTransactionDate ? logFormTransactionDate : '',
      priority:
        logFormType === LogFormActivity
          ? ''
          : logFormSuspense?.PriorityId
          ? logFormSuspense.PriorityId
          : 5,
      assignedtoradio: logFormSuspense?.SuspGrpId ? LogFormGroupAssigned : LogFormEmployeeAssigned,
      assignedtodropdn,
      activitytype: logFormSuspense?.Activity ? `${logFormSuspense.Activity}` : '',
      descriptiontype: logFormSuspense?.SubActivity ? `${logFormSuspense.SubActivity}` : '',
      stage: logFormSuspense?.StageId ? `${logFormSuspense.StageId.slice(0, -6)}+00:00` : '',
      premium: logFormSuspense?.Premium ? parseInt(logFormSuspense?.Premium, 10) : '',
      closedate: logFormSuspense?.CloseDate ? formatDate(logFormSuspense.CloseDate) : '',
      sharetoinsurlink: false,
      summary: logFormSuspense ? logFormSuspense.Description : '',
      duedate: logFormSuspense?.DueDate
        ? formatDate(logFormSuspense.DueDate)
        : logFormType === LogFormActivity
        ? formatDate(new Date().getDate())
        : '',
      action: logFormSuspense?.Action ? `${logFormSuspense.Action}` : '',
      assignedtoname: logFormSuspense?.AssignedToName ? `${logFormSuspense.AssignedToName}` : '',
      reschedno: logFormSuspense?.ReschedNo ? `${logFormSuspense.ReschedNo}` : '0',
      extrasummary: '',
      file: '',
    };
  };

  const handleClose = () => {
    hideLogForm();
  };

  const fromWorkItem = logFormSuspense ? true : false;
  const logformTitle = fromWorkItem
    ? formatMessage({ id: 'logform.title.update' })
    : formatMessage({ id: 'logform.title' });

  let isCompleted = fromWorkItem ? true : false;

  const createLog = async (values) => {
    const logFormData = {
      id: logFormSuspense?.Id ? `${logFormSuspense.Id}` : '',
      LogFormType: logFormType,
      PremiumValue: values.premium,
      ...values,
    } as LogFormData;

    isCompleted = values.status === 2 ? true : false;

    // if no activity type is selected on suspense, set it to -1
    if (logFormData.activitytype === '') {
      logFormData.activitytype = '-1';
    }
    // if no description selected, set it to -1
    if (logFormData.descriptiontype === '') {
      logFormData.descriptiontype = '-1';
    }
    return await LogFormServices.create(logFormData);
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(createLog, {
    onError: (error, variables, context) => {
      // An error happened!
      addErrorToast(
        <FormattedMessage
          id={
            logFormType === LogFormActivity
              ? 'logform.activityCreateError'
              : 'logform.suspenseCreateError'
          }
        />,
        {
          title: <FormattedMessage id="error.toasts.header" />,
          withBottomActionBar: true,
        }
      );
    },
    onSuccess: (data, variables, context) => {
      hideLogForm();
      if (data === null) {
        addErrorToast(
          <FormattedMessage
            id={
              logFormType === LogFormActivity
                ? 'logform.activityCreateError'
                : 'logform.suspenseCreateError'
            }
          />,
          {
            title: <FormattedMessage id="error.toasts.header" />,
            withBottomActionBar: true,
          }
        );
      } else {
        addSuccessToast(
          <FormattedMessage
            id={
              logFormType === LogFormActivity
                ? 'logform.activityCreateSuccess'
                : 'logform.suspenseCreateSuccess'
            }
          />,
          {
            title: <FormattedMessage id="success.toasts.header" />,
            withBottomActionBar: true,
          }
        );
      }

      queryClient.invalidateQueries(
        logFormType === LogFormActivity ? ['activity'] : ['workInProgress']
      );
      // Forcing a full refresh of the dashboard as there is too much logic at the SDS/Stored proc. to be brought to the UI just to update one element.
      queryClient.invalidateQueries(['toDoListWorkgroup']);
      queryClient.invalidateQueries(['toDoList']);
      if (logFormType === LogFormSuspense && isCompleted) {
        queryClient.invalidateQueries(['activity']);
      }
    },
  });

  let childHandle: Handle<typeof LogFormControl>;

  return (
    logFormVisible && (
      <Modal
        onClose={() => handleClose()}
        large
        scrollable
        title={logformTitle}
        footer={
          <ButtonGroup endAlign={true}>
            <Button
              variant="tertiary"
              disabled={!logFormCustomer}
              onClick={() => window.open(`${baseUrl}${logFormCustomer?.value}`)}
            >
              {formatMessage({ id: 'logform.viewcustomer' })}
            </Button>
            <Button variant="tertiary" onClick={() => handleClose()}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              variant="primary"
              disabled={fromWorkItem && (logFormSuspense?.IsCompleted ? true : false)}
              active={!(fromWorkItem && (logFormSuspense?.IsCompleted ? true : false))}
              onClick={() => childHandle.SubmitForm()}
            >
              <FormattedMessage id="logform.savelog" />
            </Button>
          </ButtonGroup>
        }
      >
        <VertaFormik
          initialValues={initialValues()}
          validationSchema={() => logFormValidationSchema(formatMessage, logFormType)}
          onSubmit={(values) => {
            return !mutation.isLoading ? mutation.mutateAsync(values) : null;
          }}
        >
          <LogFormControl
            logFormType={logFormType}
            selectedItem={logFormCustomer}
            setSelectedItem={setLogFormCustomer}
            ref={(c) => (childHandle = c)}
            fromWorkItem={fromWorkItem}
            isCompleted={logFormSuspense?.IsCompleted ? true : false}
            suspid={logFormSuspense?.Id ? `${logFormSuspense.Id}` : ''}
          ></LogFormControl>
        </VertaFormik>
      </Modal>
    )
  );
};
export default LogForm;
