import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { QuickNavAction } from '../../../models/enums/QuickNavActions';
import { PolicyReduced } from '../../../models/PolicySummary';
import { PssCustomer } from '../../../models/PssCustomer';
import { PublishedProcessRestModel } from '../../../models/smartflow/PublishedProcessRestModel';
import { getPublishedProcesses } from '../../../services/quicknav/configurationService';
import {
  initiateExistingPolicyQuickNav,
  initiateNewPolicyQuickNav,
} from '../../../services/quicknav/quicknavIteraction';
import { formatCustomerName } from '../../common/utils';

export const usePublishedProcesses = (
  options?: UseQueryOptions<PublishedProcessRestModel[], unknown, PublishedProcessRestModel[]>
) => {
  const publishedProcesses = useQuery<PublishedProcessRestModel[]>(
    ['PublishedProcessRestModel'],
    () => getPublishedProcesses(),
    options
  );

  return publishedProcesses;
};

export interface mutationProps {
  policy?: PolicyReduced;
  quickNavAction: QuickNavAction;
  quickNav: PublishedProcessRestModel;
  selectedVariation: PublishedProcessRestModel;
  customer?: PssCustomer;
  customerNameString?: string;
}

const createQuickNav = ({
  policy,
  quickNavAction,
  quickNav,
  selectedVariation,
  customer,
  customerNameString,
}: mutationProps) => {
  if (!customer && (customerNameString ?? '') === '') {
    throw new Error('Need either a Customer or a customer name');
  }

  const customerName = customer ? formatCustomerName(customer) : customerNameString;
  switch (quickNavAction) {
    case QuickNavAction.NewPolicy:
      if (!customer) {
        throw new Error('Customer cannot be empty when creating a new policy from scratch');
      }
      return initiateNewPolicyQuickNav(
        customer.CustomerId,
        customerName,
        quickNav,
        selectedVariation
      );
    case QuickNavAction.PolicyCancellation:
    case QuickNavAction.PolicyEndorsement:
    case QuickNavAction.PolicyRenewal:
      return initiateExistingPolicyQuickNav(policy, customerName, quickNav, selectedVariation);
  }
};

export const useMutationPublishing = (
  options?: UseMutationOptions<mutationProps, any, any, any>
): UseMutationResult<mutationProps, any, any, any> => {
  return useMutation<mutationProps, any, any, any>(createQuickNav, options);
};
