import {
  Button,
  Column,
  Divider,
  FormField,
  Row,
  SectionContainer,
  Spacer,
  Typography,
} from 'alloy-foundation';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useFormatting } from '../../common/hooks/useFormatting';
import ChangeBrokerageModal from './ChangeBrokerageModal';
import { useProductInfoProvider } from '../../productInfo/ProductInfoProvider';

const MyProfileBrokerageInfo = () => {
  const { getLocationInfo, setAvailable } = useProductInfoProvider();
  const locationInfo = getLocationInfo();
  const { formatPhoneNumber } = useFormatting();
  const { formatMessage } = useIntl();
  const [brokModalVisible, setbrokModalVisible] = useState(false);
  const history = useHistory();

  const addPhoneNumber = (
    title: string,
    areaCode: string,
    phoneNumber: string,
    extension: string
  ) => {
    if ((phoneNumber ?? '') === '') {
      return <React.Fragment />;
    }

    return (
      <Row>
        <Column md={3}>
          <Spacer mb="none">
            <FormField label={formatMessage({ id: `contactMethods.${title}` })}>
              <Typography
                variant="body"
                data-testid={`CustomerSummary-ContactMethods-details-${title}`}
              >
                {formatPhoneNumber(areaCode, phoneNumber, extension)}
              </Typography>
            </FormField>
          </Spacer>
        </Column>
      </Row>
    );
  };

  return (
    <SectionContainer
      headerText={formatMessage({ id: 'myProfile.brokerageInfo.header' })}
      headerContent={
        <Button variant="primary" onClick={() => setbrokModalVisible(true)}>
          <FormattedMessage id="myProfile.brokerageInfo.changeBrokerage.button" />
        </Button>
      }
    >
      {brokModalVisible ? (
        <ChangeBrokerageModal
          onCancel={() => setbrokModalVisible(false)}
          onChangeBrokerage={() => {
            history.push('/Login/SelectAgency');
            setAvailable(false);
            setbrokModalVisible(false);
          }}
        />
      ) : null}
      <Row>
        <Column md={12}>
          <Spacer pb="medium">
            <Typography variant="h4">
              <FormattedMessage id="myProfile.basicInfo.header" />
            </Typography>
          </Spacer>
        </Column>
      </Row>
      <Row>
        <Column xs={12} sm={12} md={6}>
          <Spacer pb="small">
            <FormField label={formatMessage({ id: 'myProfile.brokerageInfo.agencyName.header' })}>
              <Typography variant="body">{locationInfo?.AgencyName}</Typography>
            </FormField>
          </Spacer>
        </Column>
        <Column xs={12} sm={12} md={6}>
          <Spacer pb="small">
            <FormField label={formatMessage({ id: 'myProfile.brokerageInfo.address.header' })}>
              <Spacer mb="tiny">
                <Typography variant="body" data-testid="">
                  {locationInfo?.AddressLine1}
                </Typography>
                <Typography variant="body" data-testid="">
                  {locationInfo?.AddressLine2}
                </Typography>
                <Typography variant="body" data-testid="">
                  {`${locationInfo?.City}, ${locationInfo?.State} ${locationInfo?.ZipCode}`}
                </Typography>
              </Spacer>
            </FormField>
          </Spacer>
        </Column>
      </Row>
      <Row>
        <Column md={12}>
          <Divider ml="none" mr="none" pt="none" pb="medium" />
          <Spacer pb="medium">
            <Typography variant="h4">
              <FormattedMessage id="myProfile.brokerageInfo.phoneNumbers.header" />
            </Typography>
          </Spacer>
        </Column>
      </Row>
      {addPhoneNumber(
        'BusinessPhone',
        locationInfo?.AreaCode,
        locationInfo?.BusinessPhone,
        locationInfo?.Extension
      )}
      {addPhoneNumber(
        'FaxNumber',
        locationInfo?.FaxAreaCode,
        locationInfo?.FaxPhone,
        locationInfo?.FaxExtension
      )}
    </SectionContainer>
  );
};

export default MyProfileBrokerageInfo;
