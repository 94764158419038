import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import WorkInProgressService from '../../../../services/workInProgress/WorkInProgressService';
import { WorkInProgressItem } from '../../../../models/WorkInProgress';
export const WORK_IN_PROGRESS_KEY = 'workInProgress';
export const useWorkInProgressData = (id: string) => {
  const workInProgressList = useQuery(
    [WORK_IN_PROGRESS_KEY, id],
    () => WorkInProgressService.list(id),
    {
      select: (data) => {
        return data?.Data.map((p, i) => {
          const dueDate =
            p.Type === 'QuickNav'
              ? DateTime.fromISO(p.DueDate.slice(0, -6), {
                  zone: 'utc',
                })
                  .toLocal()
                  .toISO()
              : p.DueDate;
          return {
            Id: p.Id,
            Type: p.Type,
            ProcessId: p.ProcessId,
            SubType: p.SubType,
            Description: p.Description,
            AssignedToCode: p.AssignedToCode,
            AssignedToId: p.AssignedToId,
            AssignedToName: p.AssignedToName,
            PolicyNumber: p.PolicyNumber,
            PolicyId: p.PolicyId,
            TypeofBusiness: p.TypeofBusiness,
            LineofBusiness: p.LineofBusiness,
            PriorityId: p.PriorityId,
            PriorityDisplayName: p.PriorityDisplayName,
            DueDate: dueDate,
            IsCompleted: p.IsCompleted,
            CanOpen: p.CanOpen,
            Action: p.Action,
            ReschedNo: p.ReschedNo,
            Activity: p.Activity,
            SubActivity: p.SubActivity,
            EndEffDate: p.EndEffDate,
            StageId: p.StageId,
            Premium: p.Premium,
            CloseDate: p.CloseDate,
            SuspGrpId: p.SuspGrpId,
          } as WorkInProgressItem;
        });
      },
    }
  );
  return workInProgressList;
};
