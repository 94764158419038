import React from 'react';
import { useIntl } from 'react-intl';
import Card from '../common/card/Card';
import ContactInformationViewOnly from './GeneralInfoViewOnly';
import { SectionInfoProps } from '../SectionInfoProps';
import { GeneralInfoEditForm } from './GeneralInfoEditForm';

const GeneralInfo = (props: SectionInfoProps) => {
  const { formatMessage } = useIntl();

  return (
    <Card
      title={formatMessage({ id: 'customer.details.generalInfo' })}
      canEdit={props.canEdit}
      onEdit={props.onEdit}
      isClosed={false}
      viewControl={
        <ContactInformationViewOnly customer={props.customer} dropdown={props.customerDropdown} />
      }
      formControl={
        <GeneralInfoEditForm
          props={props}
          parentNamespace={props.namespace}
          sigPermissions={props.customer.data.Permissions}
        />
      }
      namespace={props.namespace}
    ></Card>
  );
};

export default GeneralInfo;
