import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Link,
  Flex,
  Spacer,
  Typography,
  Group,
  Button,
  LoadingIndicator,
  Icon,
  Tooltip,
} from 'alloy-foundation';
import { UseQueryResult } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { PssCustomer } from '../../../../models/PssCustomer';
import { useCustomerLockData } from './useCustomerLockData';
import CustomerLockService from '../../../../services/customerLock/CustomerLockService';
import { useLockProvider } from '../../../common/providers/LockProvider';
import { useEditProvider } from '../../../common/providers/EditProvider';
import { useCommunicationService } from '../../../communicationService/CommunicationServiceProvider';
import { formatCustomerName } from '../../../common/utils';
import { useLogFormProvider } from '../../../common/providers/LogFormProvider';
import { useProductInfoProvider } from '../../../productInfo/ProductInfoProvider';

type HeaderParams = {
  id: string;
};

const Header = ({
  customer,
  updateCanEdit,
}: {
  customer: UseQueryResult<PssCustomer, unknown>;
  updateCanEdit?: CallableFunction;
}) => {
  // Commercial companies don't have a first name and store their FirmName in the lastname variable. And we aren't showing the DBA name
  const customerName = formatCustomerName(customer.data);
  const { showForm } = useCommunicationService();
  const { currentLock, setCurrentLock } = useLockProvider();
  const { editMode } = useEditProvider();
  const { id } = useParams<HeaderParams>();
  const { data, refetch } = useCustomerLockData(id);
  const { getEmpCode } = useProductInfoProvider();
  const empCode = getEmpCode();
  const renderLock = useRef(<></>);
  const { formatMessage } = useIntl();

  const renderLockedState = () => {
    const tooltipContent = (
      <Typography variant="body">
        {`${formatMessage({ id: 'customer.lock.message.name' })}: ${currentLock?.FirstName} ${
          currentLock?.LastName
        }`}
        <br />
        {`${formatMessage({ id: 'customer.lock.message.workstation' })}: ${
          currentLock?.WorkStation
        }`}
        <br />
        {`${formatMessage({ id: 'customer.lock.message.branch' })}: ${currentLock?.BranchName}`}
      </Typography>
    );
    updateCanEdit(false);
    renderLock.current = (
      <Tooltip content={tooltipContent}>
        {' '}
        <Icon
          name="locked"
          size={20}
          color="blue"
          style={{ marginBottom: '2px' }}
          testId="small-lock"
        />
      </Tooltip>
    );
  };
  useEffect(() => {
    if (editMode || !currentLock) {
      renderLock.current = <></>;
      if (updateCanEdit) updateCanEdit(true);
    } else if (currentLock?.EmpCode !== empCode || currentLock?.WorkStation !== 'MBH') {
      renderLockedState();
    }
  });

  useEffect(() => {
    setCurrentLock(data ?? null);
  }, [data, setCurrentLock]);
  const deleteLock = async (custId) => {
    return await CustomerLockService.delete(custId).then((res) => {
      setCurrentLock(null);
      refetch();
    });
  };

  const logForm = useLogFormProvider();

  return customer.isLoading ? (
    <LoadingIndicator />
  ) : (
    <React.Fragment>
      <Flex flexDirection="row" alignItems="center" data-testid="header">
        <Group spacing="small" stacked={false}>
          <Typography variant="h3">{customerName}</Typography>
          <Typography variant="disclaimer">{`(ID#${customer.data?.CustomerNumber})`}</Typography>
          <Link
            icon="open"
            onClick={() =>
              showForm('CustomerProfile', {
                Action: 'Load',
                Mode: 'PolicyTab',
                CustId: customer.data?.CustomerId,
              })
            }
            iconSize={18}
            data-testid="open-in-sig-link"
          />
          {currentLock?.CustId === customer.data?.CustomerId &&
            currentLock?.EmpCode === empCode &&
            currentLock?.WorkStation === 'MBH' &&
            editMode === false && (
              <Button
                icon="unlocked"
                variant="secondary"
                data-testid="unlock_cust"
                onClick={() => deleteLock(id)}
              >
                <FormattedMessage id="customer.unlock" />
              </Button>
            )}
          {renderLock.current}
        </Group>
      </Flex>
      <Flex justifyContent="flex-end">
        <Spacer mr="small">
          <Group spacing="small" stacked={false}>
            <Button
              variant="secondary"
              onClick={() => logForm.showLogForm('Activity', customer.data)}
              data-testid="new_activity"
            >
              <FormattedMessage id="common.actions.newActivity" />
            </Button>
            <Button
              variant="secondary"
              onClick={() => logForm.showLogForm('Suspense', customer.data)}
              data-testid="new_suspense"
            >
              <FormattedMessage id="common.actions.newSuspense" />
            </Button>
          </Group>
        </Spacer>
      </Flex>
    </React.Fragment>
  );
};

export default Header;
