import React from 'react';
import {
  Typography,
  LoadingIndicator,
  Container,
  Row,
  Column,
  Spacer,
  FormField,
} from 'alloy-foundation';
import { UseQueryResult } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { PssCustomer } from '../../../../models/PssCustomer';
import Divider from '../../../common/divider/Divider';
import { CustomerDropdowns } from '../../../../models/CustomerDropdowns';
import { useFormatting } from '../../../common/hooks/useFormatting';

const GeneralInfoViewOnly = ({
  customer,
  dropdown,
}: {
  customer: UseQueryResult<PssCustomer, unknown>;
  dropdown: UseQueryResult<CustomerDropdowns, unknown>;
}) => {
  const { formatMessage } = useIntl();
  const { formatDate } = useFormatting();

  const personalCustomerInfo = (
    <div data-testid="Customer-General-Info-Personal">
      <Row>
        {(customer.data?.PrimarySalutation ?? '') !== '' && (
          <Column md={3}>
            <Spacer mb="none">
              <FormField label={formatMessage({ id: 'customer.details.personal.prefix.label' })}>
                <Typography variant="body" data-testid="Primary-Prefix-Detail">
                  {customer.data?.PrimarySalutation}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
        )}

        {(customer.data?.FirstName ?? '') !== '' && (
          <Column md={3}>
            <Spacer mb="none">
              <FormField label={formatMessage({ id: 'customer.details.personal.firstname.label' })}>
                <Typography variant="body" data-testid="Primary-FirstName-Detail">
                  {customer.data?.FirstName}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
        )}

        {(customer.data?.MiddleName ?? '') !== '' && (
          <Column md={3}>
            <Spacer mb="none">
              <FormField
                label={formatMessage({ id: 'customer.details.personal.middlename.label' })}
              >
                <Typography variant="body" data-testid="Primary-MiddleName-Detail">
                  {customer.data?.MiddleName}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
        )}

        {(customer.data?.LastName ?? '') !== '' && (
          <Column md={3}>
            <Spacer mb="none">
              <FormField label={formatMessage({ id: 'customer.details.personal.lastname.label' })}>
                <Typography variant="body" data-testid="Primary-LastName-Detail">
                  {customer.data?.LastName}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
        )}
      </Row>
      {((customer.data?.SecondarySalutation ?? '') !== '' ||
        (customer.data?.SecondaryFirstName ?? '') !== '' ||
        (customer.data?.SecondaryMiddleName ?? '') !== '' ||
        (customer.data?.SecondaryLastName ?? '') !== '') && (
        <Row>
          {(customer.data?.SecondarySalutation ?? '') !== '' && (
            <Column md={3}>
              <Spacer mb="none">
                <FormField
                  label={formatMessage({ id: 'customer.details.personal.prefix.sc.label' })}
                >
                  <Typography variant="body" data-testid="Secondary-Prefix-Detail">
                    {customer.data?.SecondarySalutation}
                  </Typography>
                </FormField>
              </Spacer>
            </Column>
          )}
          {(customer.data?.SecondaryFirstName ?? '') !== '' && (
            <Column md={3}>
              <Spacer mb="none">
                <FormField
                  label={formatMessage({ id: 'customer.details.personal.firstname.sc.label' })}
                >
                  <Typography variant="body" data-testid="Secondary-FirstName-Detail">
                    {customer.data?.SecondaryFirstName}
                  </Typography>
                </FormField>
              </Spacer>
            </Column>
          )}
          {(customer.data?.SecondaryMiddleName ?? '') !== '' && (
            <Column md={3}>
              <Spacer mb="none">
                <FormField
                  label={formatMessage({ id: 'customer.details.personal.middlename.sc.label' })}
                >
                  <Typography variant="body" data-testid="Secondary-MiddleName-Detail">
                    {customer.data?.SecondaryMiddleName}
                  </Typography>
                </FormField>
              </Spacer>
            </Column>
          )}

          {(customer.data?.SecondaryLastName ?? '') !== '' && (
            <Column md={3}>
              <Spacer mb="none">
                <FormField
                  label={formatMessage({ id: 'customer.details.personal.lastname.sc.label' })}
                >
                  <Typography variant="body" data-testid="Secondary-LastName-Detail">
                    {customer.data?.SecondaryLastName}
                  </Typography>
                </FormField>
              </Spacer>
            </Column>
          )}
        </Row>
      )}
    </div>
  );

  const commercialCustomerInfo = (
    <div data-testid="Customer-General-Info-Commercial">
      <Row>
        {(customer.data?.FirmName ?? '') !== '' && (
          <Column md={3}>
            <Spacer mb="none">
              <FormField
                label={formatMessage({ id: 'customer.details.commercial.firmname.label' })}
              >
                <Typography variant="body" data-testid="Primary-firmname-Detail">
                  {customer.data?.FirmName}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
        )}

        {(customer.data?.DoingBusinessAs ?? '') !== '' && (
          <Column md={3}>
            <Spacer mb="none">
              <FormField label={formatMessage({ id: 'customer.details.commercial.firmdba.label' })}>
                <Typography variant="body" data-testid="Primary-DBA-Detail">
                  {customer.data?.DoingBusinessAs}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
        )}
      </Row>
    </div>
  );

  const customerClass = (index: number) => {
    const titleId = `customer.details.businessInformation.customerClasses.${index}`;

    const customerClassName = `CustomerClass${index}`;

    return (customer.data?.CustomerClasses[customerClassName]?.key ?? '') !== '' ? (
      <Column md={3}>
        <Spacer mb="none">
          <FormField label={formatMessage({ id: titleId })}>
            <Typography variant="body" data-testid={`${customerClassName}-Detail`}>
              {customer.data?.CustomerClasses[customerClassName]?.value}
            </Typography>
          </FormField>
        </Spacer>
      </Column>
    ) : null;
  };

  const customerClasses = () =>
    customer.data?.CustomerClasses &&
    // Back-end return 4 customer class object even if they don't exist in the database.
    ((customer.data?.CustomerClasses['CustomerClass1']?.key ?? '') !== '' ||
      (customer.data?.CustomerClasses['CustomerClass2']?.key ?? '') !== '' ||
      (customer.data?.CustomerClasses['CustomerClass3']?.key ?? '') !== '' ||
      (customer.data?.CustomerClasses['CustomerClass4']?.key ?? '') !== '') && (
      <div data-testid="Customer-General-Info-CustomerClass">
        <Row>
          {customerClass(1)}
          {customerClass(2)}
          {customerClass(3)}
          {customerClass(4)}
        </Row>
        <Divider pt="none" pb="medium" ml="none" mr="none" />
      </div>
    );

  const privacyAct = () => {
    return (
      <Row>
        <Column md={3}>
          <Spacer mb="none">
            <FormField label={formatMessage({ id: 'customer.details.personalInformationConsent' })}>
              <Typography variant="body" data-testid="PersonalInformationConsent-Detail">
                {customer.data?.PersonalInformationConsent == null
                  ? '--'
                  : formatMessage({
                      id: customer.data?.PersonalInformationConsent ? 'common.yes' : 'common.no',
                    })}
              </Typography>
            </FormField>
          </Spacer>
        </Column>
        <Column md={3}>
          <Spacer mb="none">
            <FormField label={formatMessage({ id: 'customer.details.consentForOtherUse' })}>
              <Typography variant="body" data-testid="ConsentOtherUse-Detail">
                {customer.data?.ConsentOtherUse == null
                  ? '--'
                  : formatMessage({
                      id: customer.data?.ConsentOtherUse ? 'common.yes' : 'common.no',
                    })}
              </Typography>
            </FormField>
          </Spacer>
        </Column>
        {(customer.data?.ConsentDate ?? '') !== '' && (
          <Column md={3}>
            <Spacer mb="none">
              <FormField label={formatMessage({ id: 'customer.details.dateOfConsent' })}>
                <Typography variant="body" data-testid="DateOfConsent-Detail">
                  {formatDate(customer.data?.ConsentDate)}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
        )}
      </Row>
    );
  };

  return customer.isLoading || dropdown.isLoading ? (
    <LoadingIndicator />
  ) : (
    <Container noPadding={true} data-testid="GeneralInfo">
      <Row>
        <Column md={3}>
          <Spacer mb="none">
            <FormField label={formatMessage({ id: 'customer.details.base.type.label' })}>
              <Typography variant="body" data-testid="CustomerType-Value">
                {
                  dropdown.data?.ClientTypes.find((t) => t.Key === customer.data?.CustomerType)
                    ?.Value
                }
              </Typography>
            </FormField>
          </Spacer>
        </Column>
        {customer.data?.CustomerType === 'B' && (
          <Column md={3}>
            <Spacer mb="none">
              <FormField label={formatMessage({ id: 'customer.details.base.subbroker.label' })}>
                <Typography variant="body" data-testid="CustomerSubBroker-Value">
                  {customer.data?.BrokerCodeDisplay}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
        )}
        <Column md={3}>
          <Spacer mb="none">
            <FormField label={formatMessage({ id: 'customer.details.base.language.label' })}>
              <Typography variant="body" data-testid="Primary-Language-Detail">
                {formatMessage({
                  id:
                    customer.data?.Language.toLocaleUpperCase() === 'E'
                      ? 'common.english'
                      : 'common.french',
                })}
              </Typography>
            </FormField>
          </Spacer>
        </Column>
      </Row>
      <Divider pt="none" pb="medium" ml="none" mr="none" />
      {customer.data?.IsPersonalCust && personalCustomerInfo}
      {customer.data?.IsCommercialCustomer && commercialCustomerInfo}
      <Divider pt="none" pb="medium" ml="none" mr="none" />
      {customerClasses()}
      {privacyAct()}
    </Container>
  );
};

export default GeneralInfoViewOnly;
