import {
  Badge,
  Button,
  ButtonGroup,
  Column,
  DropdownSingleSelectTypeaheadSearch,
  Flex,
  Group,
  Icon,
  LeaveWithoutSaveModal,
  LoadingIndicator,
  Pagination,
  Row,
  SectionContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableRowCountSelector,
  TextTruncateWithTooltip,
  Typography,
  useAppContext,
} from 'alloy-foundation';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { ReassignEmployeeArr } from '../../../models/smartflow/ReassignEmployees';
import { WorkItemRestModel, SelectedAssigneesModel } from '../../../models/WorkItemRestModel';
import { LocalizedLeaveWithoutSaveGuard } from '../../common/localizedLeaveWithoutSaveGuard/LocalizedLeaveWithoutSaveGuard';
import { getComparator, Order } from '../../common/tableUtilities';
import { convertToDateFormat, isPastDue } from '../../common/utils';
import ReassignQuickNavTableHead from './ReassignQuickNavTableHead';
import { useAssigneesData, useReassign } from './useManagerDashboard';
export type ReassignSortableKeys = {
  Title: string;
  CurrentTaskTitle: string;
  CurrentTaskDueDate: string;
  AssignedToName: string;
  ClientName: string;
};
const ReassignQuickNavGrid = ({
  selectedRows,
  setReassignVisible,
  onPageChange,
}: {
  selectedRows: WorkItemRestModel[];
  setReassignVisible: (value) => void;
  onPageChange: (nextPage: number) => void;
}) => {
  const tableRowRanges = [5, 10, 25];

  const stylesFn = () => ({
    dropdown: {
      width: '230px',
    },
  });
  const { addSuccessToast } = useAppContext();
  const { css, styles } = useStyles({ stylesFn });
  const [selectedItems, setSelectedItems] = useState<WorkItemRestModel[]>();
  const [selectedAssignees, setSelectedAssginees] = useState<SelectedAssigneesModel[]>([]);
  const [filteredDropdown, setFilteredDropdown] = useState([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const history = useHistory();
  const customerIds = [];
  selectedRows.forEach((item) => {
    if (!customerIds.includes(item.ClientId)) customerIds.push(item.ClientId);
  });
  const queries = useAssigneesData(customerIds);
  const reassignMutation = useReassign({
    onSuccess() {
      addSuccessToast(<FormattedMessage id="dashboard.manager.reassign.quicknav.toast.success" />, {
        title: <FormattedMessage id="success.toasts.header" />,
        withBottomActionBar: true,
      });
      queryClient.invalidateQueries(['managerWorkItems']).then(() => setReassignVisible(false));
    },
  });
  const { formatMessage } = useIntl();
  const isLoading = queries.some((result) => result.isLoading);
  const isError = queries.some((result) => result.isError);
  const [inputValue, setInputValue] = useState([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ReassignSortableKeys>('AssignedToName');
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const handleRequestSort = (
    _event: MouseEvent | React.KeyboardEvent,
    property: keyof ReassignSortableKeys
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const onInputValueChange = (_inputValue, itemId) => {
    const newArr = [...inputValue];
    const index = newArr.findIndex((i) => i.itemId === itemId);
    if (index !== -1) newArr[index] = { _inputValue, itemId };
    else newArr.push({ _inputValue, itemId });
    setInputValue(newArr);
  };
  const handleChange = (e, itemId) => {
    const newArr = [...selectedAssignees];
    const index = newArr?.findIndex((i) => i.itemId === itemId);
    if (index !== -1) newArr[index] = { ...e, itemId };
    else newArr.push({ ...e, itemId });
    setSelectedAssginees(newArr);
  };
  const handleInputClear = (itemId) => {
    const newArr = [...selectedAssignees];
    const index = newArr?.findIndex((i) => i.itemId === itemId);
    newArr.splice(index, 1);
    setSelectedAssginees(newArr);
  };
  const handleDeleteRow = (e, rowIndex) => {
    const filtered = selectedItems.filter((item) => item.Id !== e);
    const dropdown = filteredDropdown.filter((item) => item.itemId !== e);
    setFilteredDropdown(dropdown);
    setSelectedItems(filtered);
    if (page > 1 && rowIndex % pageSize === 0) setPage(page - 1);
  };
  const handleCancel = () => {
    if (isDirty) setModalVisible(true);
    else setReassignVisible(false);
    onPageChange(0);
  };

  useEffect(() => {
    // Assign available assignees to workitem model once only
    if (!isLoading && !selectedItems) {
      selectedRows?.forEach((row) => {
        const reassignEmpArr =
          (
            queries.find((item) => {
              const reassignArr = item.data as ReassignEmployeeArr;
              return row.ClientId === reassignArr?.CustomerId;
            })?.data as ReassignEmployeeArr
          )?.ReassignEmpArr ?? [];
        // Filter out the employee that is currently assigned to this work item
        const filteredEmpArr = reassignEmpArr.filter((i) => i.VimId !== row.AssignedToId);
        row.ReassignEmpArr = filteredEmpArr;
      });
      setSelectedItems(selectedRows);
    }
  }, [isLoading, queries, selectedRows, selectedItems]);
  useEffect(() => {
    // Checking if all work items are selected with an assignee, and tracking dirty form
    if (selectedItems && selectedAssignees) {
      let isReady = true;
      let dirty = false;
      selectedItems?.forEach((item) => {
        const dropdownValue = selectedAssignees?.find((i) => item.Id === i.itemId);
        if (!dropdownValue || !dropdownValue?.value) isReady = false;
        else if (dropdownValue?.value) dirty = true;
      });
      setAllSelected(isReady);
      setIsDirty(dirty);
    }
  }, [selectedItems, selectedAssignees]);
  useEffect(() => {
    // As inputvalue changes, update dropdown options
    const dropdown = [];
    inputValue.forEach((item) => {
      const assignees = selectedItems.find((i) => i.Id === item.itemId);
      const filtered = assignees?.ReassignEmpArr?.filter((i) =>
        i.DisplayName.toLowerCase().includes(item._inputValue.toLowerCase())
      );
      dropdown.push({ itemId: item.itemId, dropdown: filtered });
    });
    setFilteredDropdown(dropdown);
  }, [inputValue, selectedItems]);

  return (
    <SectionContainer
      headerText={formatMessage({ id: 'dashboard.manager.reassign.quicknav.title' })}
    >
      {!isLoading && !isError && selectedItems && (
        <Group spacing="medium">
          <Table>
            <ReassignQuickNavTableHead
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {selectedItems
                .sort(
                  getComparator<keyof ReassignSortableKeys>(
                    order,
                    orderBy,
                    orderBy === 'CurrentTaskDueDate'
                  )
                )
                .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
                .map((k, j) => {
                  const value = convertToDateFormat(k.CurrentTaskDueDate) ?? '';
                  const pastDue = isPastDue(k.CurrentTaskDueDate);
                  const index = selectedAssignees?.findIndex((i) => i.itemId === k.Id);
                  const index2 = inputValue.findIndex((i) => i.itemId === k.Id);
                  const filterIndex = filteredDropdown.findIndex((i) => i.itemId === k.Id);
                  return (
                    <TableRow key={k.Id}>
                      <TableCell data-testid={`row-${j}-AssignedTo`}>
                        <Typography variant="bodySm" noWrap={true}>
                          {k.AssignedToName ?? ''}
                        </Typography>
                      </TableCell>
                      <TableCell data-testid={`row-${j}-DueDate`}>
                        <Badge color={pastDue ? 'red' : 'green'} inverse>
                          {k.Priority < 3 ? (
                            <Group spacing="tiny" stacked={false}>
                              <Icon name="flag" size={12} color="red" />
                              {value}
                            </Group>
                          ) : (
                            value
                          )}
                        </Badge>
                      </TableCell>
                      <TableCell width={200} data-testid={`row-${j}-CurrentStep`}>
                        <TextTruncateWithTooltip
                          lines={1}
                          text={k.CurrentTaskTitle ?? ''}
                          width={120}
                          typographyProps={{ variant: 'bodySm' }}
                        />
                      </TableCell>
                      <TableCell width={140} data-testid={`row-${j}-Customer`}>
                        <TextTruncateWithTooltip
                          lines={1}
                          text={k.ClientName ?? ''}
                          width={80}
                          typographyProps={{ variant: 'bodySm' }}
                        />
                        <TextTruncateWithTooltip
                          lines={1}
                          text={k.PolicyNumber ?? ''}
                          width={80}
                          typographyProps={{ variant: 'bodySm' }}
                        />
                      </TableCell>
                      <TableCell width={200} data-testid={`row-${j}-Type`}>
                        <TextTruncateWithTooltip
                          lines={1}
                          text={k.Title ?? ''}
                          width={120}
                          typographyProps={{ variant: 'bodySm' }}
                        />
                      </TableCell>
                      <TableCell>
                        <div {...css(styles.dropdown)}>
                          <DropdownSingleSelectTypeaheadSearch
                            id={`dropdown-${j}`}
                            placeholder={formatMessage({
                              id: 'dashboard.manager.reassign.quicknav.placeholder',
                            })}
                            renderErrorMessage={false}
                            options={
                              filteredDropdown[filterIndex]?.dropdown
                                ? filteredDropdown[filterIndex].dropdown.map((item) => {
                                    return {
                                      value: item.VimId.toString() ?? '',
                                      label: item.DisplayName ?? '',
                                    };
                                  })
                                : k.ReassignEmpArr.map((item) => {
                                    return {
                                      value: item.VimId.toString() ?? '',
                                      label: item.DisplayName ?? '',
                                    };
                                  })
                            }
                            selectedItem={
                              selectedAssignees[index]?.value
                                ? {
                                    value: selectedAssignees[index].value,
                                    label: selectedAssignees[index].label,
                                  }
                                : null
                            }
                            label=""
                            hideLabel={true}
                            onChange={(e) => handleChange(e, k.Id)}
                            onInputValueChange={(e) => onInputValueChange(e, k.Id)}
                            onInputClear={() => handleInputClear(k.Id)}
                            inputValue={inputValue[index2]?._inputValue ?? ''}
                            clearButtonAriaLabel="clearButton"
                          ></DropdownSingleSelectTypeaheadSearch>
                        </div>
                      </TableCell>
                      <TableCell width={30}>
                        {selectedItems.length > 1 ? (
                          <Button
                            data-testid={`delete-${j}`}
                            icon="remove"
                            onClick={() => handleDeleteRow(k.Id, j)}
                            variant="link"
                          ></Button>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <Row>
            <Column>
              <TableRowCountSelector
                afterDropdownText={<FormattedMessage id="datatable.rowcount.afterText" />}
                beforeDropdownText={<FormattedMessage id="datatable.rowcount.beforeText" />}
                onChange={({ value }) => {
                  setPageSize(value);
                  setPage(1);
                }}
                rowRanges={tableRowRanges}
                rowsToDisplay={pageSize}
              />
            </Column>
            <Column>
              <Flex justifyContent="flex-end">
                <Pagination
                  pageCount={Math.ceil(selectedItems?.length / pageSize)}
                  onPageChange={({ selected }) => {
                    setPage(selected + 1);
                  }}
                  previousLabel={<FormattedMessage id="datatable.paging.previous" />}
                  nextLabel={<FormattedMessage id="datatable.paging.next" />}
                  forcePage={page - 1}
                />
              </Flex>
            </Column>
          </Row>
          <Flex justifyContent="flex-end">
            <ButtonGroup>
              <Button variant="tertiary" onClick={handleCancel}>
                <FormattedMessage id="common.button.cancel" />
              </Button>
              <Button
                variant="primary"
                disabled={!allSelected || reassignMutation?.isLoading}
                onClick={() =>
                  reassignMutation?.mutate({
                    selectedWorkItems: selectedItems,
                    selectedAssignees,
                  })
                }
              >
                <FormattedMessage id="dashboard.manager.panel.options.reassign" />
              </Button>
            </ButtonGroup>
          </Flex>
        </Group>
      )}
      <LocalizedLeaveWithoutSaveGuard
        shouldBlockNavigation={() => {
          return isDirty;
        }}
        navigate={(path) => {
          history.push(path);
        }}
      />
      {modalVisible && (
        <LeaveWithoutSaveModal
          title={formatMessage({ id: 'dashboard.manager.reassign.quicknav.modal.title' })}
          children={null}
          subtitle={formatMessage({
            id: 'dashboard.manager.reassign.quicknav.modal.description',
          })}
          cancelNavigationButtonText={formatMessage({
            id: 'dashboard.manager.reassign.quicknav.modal.continue',
          })}
          confirmNavigationButtonText={formatMessage({
            id: 'dashboard.manager.reassign.quicknav.modal.leave',
          })}
          onConfirmNavigationClick={() => setReassignVisible(false)}
          onClose={() => setModalVisible(false)}
        />
      )}
      {isLoading && <LoadingIndicator />}
      {isError && (
        <Typography variant="disclaimer">
          <FormattedMessage id="common.error_loading_data" />
        </Typography>
      )}
    </SectionContainer>
  );
};

export default ReassignQuickNavGrid;
