import {
  MonEspaceCourtierSvg,
  MyBrokerHomeSvg,
  TopNavBrand,
  TopNavButtonGroup,
} from 'alloy-foundation';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import GlobalNavService from '../../services/globalnav/GlobalNavService';
import { Locale } from '../localization/Locale';
import { useLocaleContext } from '../localization/LocaleContext';
import TopNavActionsSection from './TopNavActionsSection';
import TopNavMyAccountSection from './TopNavMyAccountSection';
import TopNavRecentCustomers from './TopNavRecentCustomers';
import TopNavSearch from './TopNavSearch';
import TopNavAdminSection from './TopNavAdminSection';
import TopNavWorkQueue from './TopNavWorkQueue';
import LogForm from './LogForm/LogForm';
import { useLogFormProvider } from '../common/providers/LogFormProvider';
import { useProductInfoProvider } from '../productInfo/ProductInfoProvider';

const AgencyTopNav = () => {
  const { getIsCustomerAdmin } = useProductInfoProvider();
  const { locale } = useLocaleContext();
  const { showLogForm } = useLogFormProvider();
  const { data } = useQuery(
    ['GlobalNav'],
    async () => {
      return await GlobalNavService.list();
    },
    { refetchOnWindowFocus: false }
  );

  const handleActivity = () => {
    showLogForm('Activity', null);
  };
  const handleSuspense = () => {
    showLogForm('Suspense', null);
  };

  return (
    <React.Fragment>
      <TopNavBrand
        to="/"
        // w=168 for english logo and w=207 for french logo
        width={locale === Locale.EnCa ? 168 : 207}
        height={18}
        DarkSVGIcon={locale === Locale.EnCa ? MyBrokerHomeSvg : MonEspaceCourtierSvg}
        aria-label="MyBrokerHome Logo"
      />
      <TopNavSearch />
      {data && (
        <TopNavActionsSection
          globalNav={data}
          handleOpenCloseActivity={handleActivity}
          handleOpenCloseSuspense={handleSuspense}
        />
      )}
      <LogForm></LogForm>
      {getIsCustomerAdmin() ? <TopNavAdminSection /> : null}
      <TopNavButtonGroup>
        <TopNavRecentCustomers />
        <TopNavWorkQueue />
      </TopNavButtonGroup>
      <TopNavMyAccountSection />
    </React.Fragment>
  );
};

export default AgencyTopNav;
