import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import {
  Column,
  Container,
  DropdownButton,
  DropdownButtonProvider,
  DropdownFilterSelect,
  DropdownLinkButton,
  DropdownList,
  DropdownListItem,
  Flex,
  FormField,
  Row,
  SectionContainer,
  Spacer,
  TableFilterArea,
  Typography,
} from 'alloy-foundation';
import type { OptionType } from 'alloy-foundation';
import { useManagerDashboard } from './useManagerDashboard';
import { DataTableColumn } from '../../data-table/DataTableColumn';
import { WorkItemRestModel } from '../../../models/WorkItemRestModel';
import { StringColumn } from '../../common/custom-datatable-columns/StringColumn';
import { DateColumn } from '../../common/custom-datatable-columns/DateColumn';
import { useDataTable, useDataTableRows } from '../../data-table/useDataTable';
import LocalizedDataTable from '../../common/localizedDataTable/LocalizedDataTable';
import { CsvDataService } from '../../../services/csvExport/CsvDataService';
import { useMultiSelect } from '../../data-table/useMultiSelect';
import { isPastDue } from '../../common/utils';
import { useProductInfoProvider } from '../../productInfo/ProductInfoProvider';
import { useDrawer } from '../../drawer/DrawerProvider';
import WorkQueueDrawer from '../../drawer/workQueue';
import NoPermissionToOpenWorkItemModal from '../../drawer/workQueue/NoPermissionToOpenWorkItemModal';
import ReassignQuickNavGrid from './ReassignQuickNavGrid';
import DeleteWorkItemModal from '../../drawer/workQueue/DeleteWorkItemModal';

const ManagerDashboard = () => {
  const [employees, updateEmployees] = useState<OptionType<number>[]>([]);
  const [quickNavTypes, updateQuickNavTypes] = useState<OptionType<string>[]>([]);
  const [statusFilter, updateStatusFilter] = useState<OptionType<number>>(null);
  const [reassignVisible, setReassignVisible] = useState(false);
  const [assignedEmployeeFilter, updateAssignedEmployeeFilter] = useState<OptionType<number>>(null);
  const [typeFilter, updateTypeFilter] = useState<OptionType<string>>(null);
  const [currentTasks, updateCurrentTask] = useState<number>(0);
  const [pastDueTasks, updatePastDuetTask] = useState<number>(0);
  const [taskToBeDeleted, updateTaskToBeDeleted] = useState<WorkItemRestModel>(null);
  const [deleteTaskModalVisible, updateDeleteTaskModalVisible] = useState<boolean>();
  const { getVimUserId } = useProductInfoProvider();

  // csvExport
  const csvColumnMapper = (item: WorkItemRestModel) => {
    return {
      'Assigned to': item.AssignedToName,
      'Current Step Due Date': new Date(item.CurrentTaskDueDate).toLocaleDateString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      'Current Step': item.CurrentTaskTitle,
      'Due Date': new Date(item.DueDate).toLocaleDateString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      Customer: item.ClientName,
      'Policy #': item.PolicyNumber,
      Type: item.Title,
    };
  };

  const query = useManagerDashboard({
    select: (data) => {
      return data.map((p, i) => {
        const dueDate = DateTime.fromISO(p.CurrentTaskDueDate, { zone: 'utc' }).toLocal().toISO();
        return {
          Id: p.Id,
          Type: p.Type,
          Link: p.Link,
          Title: p.Title,
          ShortTitle: p.ShortTitle,
          Status: p.Status,
          DueDate: p.DueDate,
          Priority: p.Priority,
          ClientId: p.ClientId,
          ClientName: p.ClientName,
          AssignedToId: p.AssignedToId,
          AssignedToName: p.AssignedToName,
          CurrentTaskTitle: p.CurrentTaskTitle,
          CurrentTaskDueDate: dueDate,
          NextTaskTitle: p.NextTaskTitle,
          PolicyId: p?.PolicyId || null,
          PolicyNumber: p.PolicyNumber,
          ReassignEmpArr: p?.ReassignEmpArr || [],
        } as WorkItemRestModel;
      });
    },
    onSuccess(data) {
      const tmpEmployees = data.map((d) => {
        return { value: d.AssignedToId, label: d.AssignedToName } as OptionType<number>;
      });

      const uniqueEmployees: OptionType<number>[] = [];

      tmpEmployees.forEach((e) => {
        if (uniqueEmployees.findIndex((ue) => ue.value === e.value) === -1) {
          uniqueEmployees.push(e);
        }
      });

      updateEmployees(uniqueEmployees.sort((e1, e2) => e1.label.localeCompare(e2.label)));

      const tmpQuickNavTypes = data.map((d) => {
        return { value: d.Title, label: d.Title } as OptionType<string>;
      });

      const uniqueQuickNavTypes: OptionType<string>[] = [];

      tmpQuickNavTypes.forEach((e) => {
        if (uniqueQuickNavTypes.findIndex((ue) => ue.value === e.value) === -1) {
          uniqueQuickNavTypes.push(e);
        }
      });

      updateQuickNavTypes(uniqueQuickNavTypes.sort((q1, q2) => q1.label.localeCompare(q2.label)));

      updateCurrentTask(data.filter((d) => !isPastDue(d.CurrentTaskDueDate)).length);
      updatePastDuetTask(data.filter((d) => isPastDue(d.CurrentTaskDueDate)).length);
    },
  });

  const { formatMessage } = useIntl();
  const { showDrawer } = useDrawer();
  const [permissionModalVisible, setPermissionModalVisible] = useState(false);
  const vimUserId = getVimUserId();

  const handleOpenWorkItem = (item: WorkItemRestModel) => {
    if (vimUserId === item.AssignedToId) showDrawer(<WorkQueueDrawer id={item.Id} />);
    else setPermissionModalVisible(true);
  };

  const handleDelete = (item: WorkItemRestModel) => {
    updateTaskToBeDeleted(item);
    updateDeleteTaskModalVisible(true);
  };

  const columns: DataTableColumn<WorkItemRestModel>[] = [
    new StringColumn<WorkItemRestModel>({
      key: 'AssignedToName',
      header: formatMessage({ id: 'dashboard.manager.panel.columns.assignedto' }),
      flex: 2,
    }),
    new DateColumn<WorkItemRestModel>({
      key: 'CurrentTaskDueDate',
      header: formatMessage({ id: 'dashboard.manager.panel.columns.stepDueDate' }),
      showFlag: (data: WorkItemRestModel) => data.Priority < 3,
      flex: 2,
    }),
    new StringColumn<WorkItemRestModel>({
      key: 'CurrentTaskTitle',
      header: formatMessage({ id: 'dashboard.manager.panel.columns.currentStep' }),
      flex: 2,
    }),
    new StringColumn<WorkItemRestModel>({
      key: 'ClientName',
      header: formatMessage({ id: 'dashboard.manager.panel.columns.customer' }),
      flex: 2,
    }),
    new StringColumn<WorkItemRestModel>({
      key: 'PolicyNumber',
      header: formatMessage({ id: 'dashboard.manager.panel.columns.policyNo' }),
      flex: 2,
      noWrap: true,
      toolTip: true,
    }),
    new StringColumn<WorkItemRestModel>({
      key: 'Title',
      header: formatMessage({ id: 'dashboard.manager.panel.columns.type' }),
      flex: 2,
    }),
    new StringColumn<WorkItemRestModel>({
      key: '',
      header: '',
      align: 'center',
      sortable: false,
      Cell: function ActionCell({ row: policy, rowIndex }) {
        // example usage - should open customer summary
        return (
          <>
            <DropdownButtonProvider>
              <DropdownLinkButton
                data-testid={`actions-link-${rowIndex}`}
                aria-label={`actions-link-${rowIndex}`}
              />
              <DropdownList>
                <DropdownListItem
                  data-testid="open-work-item"
                  onSelect={() => handleOpenWorkItem(policy)}
                >
                  {formatMessage({ id: 'dashboard.manager.panel.action.open' })}
                </DropdownListItem>
                <DropdownListItem onSelect={() => handleDelete(policy)}>
                  {formatMessage({ id: 'dashboard.manager.panel.action.delete' })}
                </DropdownListItem>
              </DropdownList>
            </DropdownButtonProvider>
          </>
        );
      },
    }),
  ];

  const displayData = useMemo(() => {
    let data = query?.data;

    if (!data || data.length === 0) return [];

    if (statusFilter?.value === 1) {
      data = data.filter((d) => !isPastDue(d.CurrentTaskDueDate));
    } else if (statusFilter?.value === 2) {
      data = data.filter((d) => {
        return isPastDue(d.CurrentTaskDueDate);
      });
    }

    if (assignedEmployeeFilter) {
      data = data.filter((d) => d.AssignedToId === assignedEmployeeFilter.value);
    }
    if (typeFilter) {
      data = data.filter((d) => d.Title === typeFilter.value);
    }

    return data;
  }, [query, statusFilter, assignedEmployeeFilter, typeFilter]);

  const multiSelect = useMultiSelect<WorkItemRestModel>(displayData, displayData, [], true, 'Id');

  const tableState = useDataTable({
    columns,
    data: displayData || [],
    filtering: false,
    rowSelection: false,
    sorting: true,
    initialSortedColumnKey: 'CurrentTaskDueDate',
    initialSortDirection: 'asc',
    expandingRows: false,
    multipleRowSelection: true,
    initialPageSize: 5,
    multiSelect,
  });
  const tableRows = useDataTableRows(tableState);

  const handleExport = () => {
    const items = query?.data.map(csvColumnMapper);

    CsvDataService.exportToCsv(
      `Manager Workflow Item ${DateTime.now().toFormat('MM-dd-yyyy')}.csv`,
      items
    );
  };
  const handleReassign = (isVisble: boolean) => {
    setReassignVisible(isVisble);
    resetFilters();
    if (!isVisble) {
      multiSelect.reset();
    }
  };
  const resetToPage1 = () => {
    tableState.onPageChange?.(0);
  };
  const resetFilters = () => {
    updateAssignedEmployeeFilter(null);
    updateTypeFilter(null);
    updateStatusFilter(null);
    resetToPage1();
  };

  return (
    <>
      {permissionModalVisible && (
        <NoPermissionToOpenWorkItemModal
          onClose={() => setPermissionModalVisible(false)}
        ></NoPermissionToOpenWorkItemModal>
      )}
      {deleteTaskModalVisible && (
        <DeleteWorkItemModal
          data={taskToBeDeleted}
          handleClose={() => updateDeleteTaskModalVisible(false)}
          handleToggle={() => {
            updateDeleteTaskModalVisible(false);
          }}
          resetMultiSelect={multiSelect.reset}
          isManager={true}
        />
      )}
      {!reassignVisible ? (
        <SectionContainer headerText={formatMessage({ id: 'dashboard.manager.panel.title' })}>
          <Row data-testid="personal">
            <Column md={2}>
              <Spacer mb="none">
                <FormField label={formatMessage({ id: 'dashboard.manager.panel.pastDue' })}>
                  <Typography variant="body" data-testid="PastDueCount">
                    {pastDueTasks}
                  </Typography>
                </FormField>
              </Spacer>
            </Column>
            <Column md={2}>
              <Spacer mb="none">
                <FormField label={formatMessage({ id: 'dashboard.manager.panel.currentDue' })}>
                  <Typography variant="body" data-testid="CurrentCount">
                    {currentTasks}
                  </Typography>
                </FormField>
              </Spacer>
            </Column>
          </Row>
          <TableFilterArea>
            <Container fluid={true} noPadding={true}>
              <Row>
                <Column md={3}>
                  <DropdownFilterSelect
                    renderErrorMessage={false}
                    label={formatMessage({ id: 'dashboard.manager.panel.filters.status' })}
                    disabled={
                      query.isLoading ||
                      (multiSelect?.selectedRows && multiSelect.selectedRows.length > 0)
                    }
                    placeholder={formatMessage({
                      id: 'dashboard.manager.panel.filters.status.placeholder',
                    })}
                    options={[
                      {
                        value: 1,
                        label: formatMessage({ id: 'dashboard.manager.panel.currentDue' }),
                      },
                      { value: 2, label: formatMessage({ id: 'dashboard.manager.panel.pastDue' }) },
                    ]}
                    onChange={(v) => {
                      updateStatusFilter(v);
                      resetToPage1();
                    }}
                    initialSelectedItem={null}
                  />
                </Column>
                <Column md={3}>
                  <DropdownFilterSelect
                    renderErrorMessage={false}
                    label={formatMessage({ id: 'dashboard.manager.panel.filters.assignedto' })}
                    disabled={
                      query.isLoading ||
                      (multiSelect?.selectedRows && multiSelect.selectedRows.length > 0)
                    }
                    options={employees}
                    placeholder={formatMessage({
                      id: 'dashboard.manager.panel.filters.assignedto.placeholder',
                    })}
                    onChange={(v) => {
                      updateAssignedEmployeeFilter(v);
                      resetToPage1();
                    }}
                    initialSelectedItem={null}
                  />
                </Column>
                <Column md={3}>
                  <DropdownFilterSelect
                    renderErrorMessage={false}
                    label={formatMessage({
                      id: 'dashboard.manager.panel.fitlers.typeofQuickNav',
                    })}
                    placeholder={formatMessage({
                      id: 'dashboard.manager.panel.filters.typeofQuickNav.placeholder',
                    })}
                    disabled={
                      query.isLoading ||
                      (multiSelect?.selectedRows && multiSelect.selectedRows.length > 0)
                    }
                    options={quickNavTypes}
                    onChange={(v) => {
                      updateTypeFilter(v);
                      resetToPage1();
                    }}
                    initialSelectedItem={null}
                  />
                </Column>
                <Column>
                  <Flex justifyContent="flex-end" height="4.25rem" alignItems="flex-end">
                    <DropdownButtonProvider>
                      <DropdownButton
                        data-testid="dashboard-panel-bulk-actions"
                        iconName="options"
                        variant="secondary"
                      >
                        <FormattedMessage id="dashboard.manager.panel.options" />
                      </DropdownButton>
                      <DropdownList>
                        {multiSelect?.selectedRows && multiSelect.selectedRows.length > 0 && (
                          <DropdownListItem onSelect={() => setReassignVisible(true)}>
                            <FormattedMessage id="dashboard.manager.panel.options.reassign" />
                          </DropdownListItem>
                        )}
                        <DropdownListItem onSelect={() => handleExport()}>
                          <FormattedMessage id="dashboard.manager.panel.options.exportAll" />
                        </DropdownListItem>
                      </DropdownList>
                    </DropdownButtonProvider>
                  </Flex>
                </Column>
              </Row>
            </Container>
          </TableFilterArea>
          <LocalizedDataTable
            {...tableState}
            {...tableRows}
            responsive={false}
            data-testId="dashboard-panel-datatable"
            isLoading={query.isLoading}
            pageSizeOptions={[5, 10, 25]}
            emptyMessage={
              <Typography variant="disclaimer">
                <FormattedMessage
                  id={query.isError ? 'common.error_loading_data' : 'dashboard.panel.data.empty'}
                />
              </Typography>
            }
          />
        </SectionContainer>
      ) : (
        <ReassignQuickNavGrid
          selectedRows={multiSelect?.selectedRows}
          setReassignVisible={handleReassign}
          onPageChange={tableState.onPageChange}
        ></ReassignQuickNavGrid>
      )}
    </>
  );
};

export default ManagerDashboard;
