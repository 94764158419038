import { DateTime } from 'luxon';
import { PolicyReduced } from '../../models/PolicySummary';
import { ExpiringPolicyItem } from '../../models/ExpiringPolicies';

export const formatMetricCardValue = function (value: string | undefined): string | undefined {
  return parseInt(value ?? '0') === 0 && value !== undefined ? '0' : value;
};

export const isEqual = (a: (string | null | boolean | undefined)[], b: (string | boolean)[]) => {
  if (a.length === b.length) {
    return a.reduce((prev, e, i) => prev && e === b[i], true);
  } else {
    return false;
  }
};

export const formatCustomerName = (customer: { FirstName: string; LastName: string }) => {
  if (customer)
    return (
      customer.LastName + ((customer?.FirstName ?? '') !== '' ? `, ${customer?.FirstName}` : '')
    );
  return '';
};

export const formatCustomerString = (clientName, clientNum, address) => {
  return `${clientName} (${clientNum})${address ? `( ${address})` : ''}`;
};

export const isPastDue = (dateString: string) => {
  const date = new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return DateTime.fromFormat(date, 'MM/dd/yyyy').startOf('day') < DateTime.now().startOf('day');
};
export const convertToDateFormat = (dateString: string) => {
  const date = new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return date;
};

export const convertSmartflowPolicyToPolicyReduced = (policy): PolicyReduced => {
  return {
    Id: policy.AttributeFields.PolicyId,
    Status: policy.AttributeFields.Status,
    PolicyNumber: policy.AttributeFields.PolicyNumber,
    PolicyType: policy.AttributeFields.PolicyType,
    TransactionType: policy.AttributeFields.TransactionType,
    EndEffDate: policy.AttributeFields.EndEffDate,
    EffectiveDate: policy.AttributeFields.EffectiveDate,
    ExpirationDate: policy.AttributeFields.ExpirationDate,
    ParentCompany: policy.AttributeFields.ParentCompany,
    Premium: policy.AttributeFields.Premium,
    PolicyClass: policy.AttributeFields.PolicyClass,
    CustId: policy.AttributeFields.ClientId,
    UserFriendlyId: policy.UserFriendlyId,
    Description: policy.AttributeFields.Description,
    LineOfBusiness: policy.AttributeFields.LineOfBusiness,
  };
};

export const convertExpiringPolicyToPolicyReduced = (policy: ExpiringPolicyItem): PolicyReduced => {
  return {
    Id: policy.PolicyId,
    Status: '',
    PolicyNumber: policy.PolicyNo,
    PolicyType: policy.PolicyType,
    // This removes the timezone that the backend return.
    EndEffDate: policy.PolicyEndEffectiveDate.substring(0, 19),
    EffectiveDate: policy.PolicyEffectiveDate,
    TransactionType: '',
    ExpirationDate: policy.PolicyExpiryDate,
    ParentCompany: policy.CompanyName,
    Premium: 0,
    PolicyClass: policy.PolicyClass,
    CustId: policy.CustomerId,
    UserFriendlyId: policy.PolicyNo,
    Description: policy.PolicyId,
    LineOfBusiness: policy.PolicyTypeLineOfBusiness,
  };
};
